import React, { Fragment, useState } from "react";
import { Accordion, Card } from "react-bootstrap";
import { AngleIcon, TashIcon, ExclamationIcon } from './../../../../../assets';
import { axiosRequest, axiosGetRequest } from './../../../../../helpers/helpers';
import ReactTooltip from "react-tooltip";
import InstructorDetailComponent from './../instructorDetailComponent';

export default function AccordionComponent(props) {
  const { event, user, handleAlert, handleCloseAndReloadData, handleUpdateEvents, listType } = props
  const [ disableRemoveButton, setDisableRemoveButton ] = useState(false)
  const [ showInstructorDetail, setShowInstructorDetail ] = useState(false)
  const [ userToShowDetails, setUserToShowDetails ] = useState({})
  const [ acceptedUsers, setAcceptedUsers] = useState([])
  const [ loader, setLoader ] = useState(false)
  const [ openAccordionKey, setOpenAccordionKey ] = useState(false)
  const [ firstApiCall, setFirstApiCall ] = useState(true)
  const [ accordionDisabled, setAccordionDisabled ] = useState(false)

  const handleShowInstructorDetails = (user) => {
    setUserToShowDetails(user)
    setShowInstructorDetail(true)
  }

  const handleCloseInstructorDetails = () => {
    setShowInstructorDetail(false)
    setUserToShowDetails({})
  }

  const processRemoveUserFromEvent = (userId) => {
    setDisableRemoveButton(true)
    let params = {
      inst_id: userId,
      preview: true
    }
    let url = `/api/v3/business_events/${event?.id}/delete_invite`;
    axiosRequest(url, 'PATCH', params, 'data').then((result) => {
      if(result?.status == 200){
        handleAlert(true, result?.success, 'success');
        handleUpdateEvents(result?.event);
      }else{
        handleAlert(true, result?.error, 'danger');
      }
      setDisableRemoveButton(false)
      setTimeout(() => {
        handleAlert(false, '', ''); 
      }, 3000)
    })
  }
  
  const handleApprove = (acceptedUserId) => {
    setDisableRemoveButton(true)
    let url = `/api/v2/sub_requests/update_status`
    let params = {
      status: 'confirm',
      event_id: event?.id,
      inst_id: acceptedUserId,
      gql_event_details: true,
      bucket_type: listType === 'Sent' ? 'sent' : ''
    }
    axiosRequest(url, 'PATCH', params, 'data').then((res) => {
      if(Object.keys(res).includes('status') && res?.status === 200){
        handleAlert(true, 'Status Updated', 'success');
      }else{
        handleAlert(true, res?.error, 'danger');
      }
      setDisableRemoveButton(false)
      setTimeout(() => {
        handleAlert(false, '', ''); 
        handleUpdateEvents(res?.event);
      }, 3000)
    })
  }

  const checkApiCallEnabled = (event) => {
    return (['Pending Approval', 'Pending Your Approval', 'Pending Manager Approval'].includes(event?.status_content) || event?.status_content.toLowerCase().includes('pending'))
  }

  const fetchAcceptedUsers = (event) => {
    if (!checkApiCallEnabled(event)) return;

    if(!firstApiCall){
      setOpenAccordionKey(!openAccordionKey)
      return;
    }else{
      setAccordionDisabled(true)
    }      

    let url = `/api/v3/business_events/${event?.id}/accepted_users`
    setLoader(true)

    axiosGetRequest(url).then((result) => {
      setAccordionDisabled(false)
      setOpenAccordionKey(true)
      setFirstApiCall(false)
      setLoader(false)
      if(result?.status == 200){
        setAcceptedUsers(result?.users)
      }else{
        handleAlert(true, result?.error, 'danger');
      }
      setTimeout(() => {
        handleAlert(false, '', ''); 
      }, 3000)
    })
  }

  return (
    <Fragment>
      {showInstructorDetail &&
        <InstructorDetailComponent showInstructorDetail={showInstructorDetail} detailInstructor={userToShowDetails} close={handleCloseInstructorDetails} handleAlert={handleAlert}/>
      }
      <Accordion className="bg-custom-gray2 rounded">
        <Card className="border-0">
          <Accordion.Toggle eventKey="0" className="bg-custom-gray2 rounded flex items-center gap-2 py-2 px-3 mt-2 w-full" onClick={() => fetchAcceptedUsers(event)} disabled={accordionDisabled}>
            <div className={`${(['Open', 'Re-Opened'].includes(event?.status_content) || event?.show_manger_request_buttons) ? 'bg-yellow-500' : 'bg-pending-event'} w-2 h-4 rounded-sm`}></div>
            <div className='text-xs font-semibold'>{event?.status_content === 'Open' ? `Invited (${event?.invited_instructors?.length})` : (['Pending Approval', 'Pending Your Approval', 'Pending Manager Approval'].includes(event?.status_content) || event?.status_content.toLowerCase().includes('pending')) && `See who accepted`}</div>
            <div>
              <AngleIcon classNames={`w-3 h-4 transform ${openAccordionKey ? 'rotate-180' : ''}`}/>
            </div>
            {loader &&
              <div className="flex items-center right-0 justify-center">
                <svg className="animate-spin h-4 w-4 text-black" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
              </div>
            }
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="0">
            <Card.Body className="p-0">
              <div className="mt-3 max-h-80 overflow-auto">
                {event?.status_content === 'Open' &&
                  <Fragment>
                    {event?.invited_instructors?.map((usr, i) => 
                      (<div key={i} className='flex items-center gap-2 justify-between p-2'>
                        <button className="flex items-center gap-2" onClick={() => handleShowInstructorDetails(usr)}>
                          <img src={usr?.image} className='w-6 h-6 rounded-full' />
                          <div>
                            <div className='custom-blue-200 text-xs font-semibold flex gap-1'>{usr?.full_name}</div>
                          </div>
                        </button>
                        {event?.show_manger_request_buttons &&
                          <Fragment>
                            {(['business_admin', 'Business Admin', 'account_owner', 'Account Owner'].includes(user?.role) || event?.admins.includes(user?.id) || event?.gfms.includes(user?.id)) &&
                              <button className={`flex justify-center items-center shadow-md rounded-full h-6 w-6 cursor-pointer bg-white ${disableRemoveButton ? 'opacity-50 cursor-not-allowed' : ''}`} onClick={() => processRemoveUserFromEvent(usr?.id)} disabled={disableRemoveButton}>
                                <TashIcon classNames={'text-black w-3.5 h-3.5'}/>
                              </button>
                            }
                          </Fragment>
                          
                        }
                      </div>)
                    )}
                  </Fragment>
                }
                {(['Pending Approval', 'Pending Your Approval', 'Pending Manager Approval'].includes(event?.status_content) || event?.status_content.toLowerCase().includes('pending')) && 
                  <Fragment>
                    {acceptedUsers?.map((usr, i) => 
                      (<div key={i} className='flex items-center justify-between gap-2 p-2'>
                        <button className="flex items-center gap-2" onClick={() => handleShowInstructorDetails(usr)}>
                          <img src={usr?.image} className='w-6 h-6 rounded-full' />
                          <div>
                            <div className='custom-blue-200 text-xs font-semibold flex gap-1 trancate'>{usr?.full_name}</div>
                          </div>
                        </button>
                        {event?.canApprove &&
                          <div className="flex gap-1 items-center">
                            {(usr?.api_sync_error_message && usr?.api_sync_error_message !== '') &&
                              <Fragment>
                                <ExclamationIcon classNames={"text-red-600 h-3.5 w-3.5 inline-block ml-1"} componentId={event?.id.toString()+'_'+usr?.id.toString()}/>
                                <ReactTooltip className='max-w-xs text-center react-tooltip-z-index-max' id={event?.id.toString()+'_'+usr?.id.toString()} place="top" effect="solid">
                                  {usr?.api_sync_error_message}
                                </ReactTooltip>
                              </Fragment>
                            }
                            <button className={`tc-11 flex items-center justify-center border-green-custom rounded-2xl h-8 gap-0.5 px-5 text-green-c ${(disableRemoveButton) ? 'opacity-50 cursor-not-allowed' : ''}`} onClick={() => handleApprove(usr?.sub_request_id)} disabled={disableRemoveButton}>
                              Approve
                            </button>
                          </div>
                        }
                      </div>)
                    )}
                  </Fragment>
                }
              </div>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </Fragment>
  );
}