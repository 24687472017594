import React, { Fragment, useState, useEffect } from "react";
import { Switch } from '@headlessui/react'
import { axiosRequest } from '../../../subRequests/helpers/helpers';
import TooltipComponent from '../tooltipComponent';

export default function AccountSubbingComponent(props){
  const { business: businessData, user, setAlert, disabledPage } = props
  const [ business, setBusiness ] = useState(businessData)
  const [ timeFrom, setTimeFrom ] = useState("")
  const [ timeTo, setTimeTo] = useState("")
  const [ hourRange, setHourRange] = useState([])
  const [ toHourRange, setToHourRange ] = useState([])
  const [ helpRange, setHelpRange] = useState([])
  const [ disableBtn, setDisableBtn ] = useState(false)

  useEffect(() => {
    setBusiness(businessData)
  }, [businessData])

  useEffect(() => {
    getFromToTime(business.business_hours)
  }, [])

  const getFromToTime = (business_hours) => {
    if(business_hours && business_hours !== "null"){
      setTimeFrom(business_hours.time_from)
      setTimeTo(business_hours.time_to)
      getBusinessRange(business_hours)
    }  
  }

  const handleSwitch = (e, name) =>{
    setBusiness(prevBusiness => ({
      ...prevBusiness,
      [name]: !prevBusiness?.[name]
    }))
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    setBusiness(prevBusiness => ({
      ...prevBusiness,
      [name]: value
    }))
  }

  const getBusinessRange = (business_hours) => {
    let start_time = '12:00 AM'
    let end_time = '11:45 PM'
  
    const convertTo24Hour = (time) => {
      const [hoursMinutes, period] = time.split(" ");
      let [hours, minutes] = hoursMinutes.split(":").map(Number);
  
      if (period === "PM" && hours !== 12) hours += 12;
      if (period === "AM" && hours === 12) hours = 0;
  
      return `${hours}:${minutes < 10 ? '0' : ''}${minutes}`;
    };
  
    const generateTimeRange = (start, end) => {
      const range = [];
      for (let hour = start; hour <= end; hour++) {
        const displayHour = hour > 12 ? hour - 12 : hour === 0 ? 12 : hour;
        const period = hour < 12 ? "AM" : "PM";
  
        range.push(`${displayHour}:00 ${period}`);
        range.push(`${displayHour}:15 ${period}`);
        range.push(`${displayHour}:30 ${period}`);
        range.push(`${displayHour}:45 ${period}`);
      }
      return range;
    };
  
    const filterRange = (range, start, end) => {
      const startIndex = range.indexOf(start);
      const endIndex = range.indexOf(end);
      return range.slice(startIndex, endIndex + 1);
    };
  
    const large_start_time = convertTo24Hour(start_time);
    const large_end_time = convertTo24Hour(end_time);
  
    const [startHour] = large_start_time.split(":").map(Number);
    const [endHour] = large_end_time.split(":").map(Number);
  
    let row_range = generateTimeRange(startHour, endHour);
  
    row_range = filterRange(row_range, start_time, end_time);
  
    const range = row_range.slice(0, row_range.length - 1);
    const from_time_index = row_range.indexOf(business_hours?.time_from);
    const to_hour_range = row_range.slice(from_time_index + 1);
  
    setHelpRange(row_range)
    setHourRange(range)
    setToHourRange(to_hour_range)
  };

  const handleStart = (e) => {
    e.preventDefault()
    const { value } = e.target
    setTimeFrom(value)
    let to_time_value = timeTo
    let from_time_index = helpRange.indexOf(value)
    let check = []
    for (let i = from_time_index +1;i < helpRange?.length; i++){
      check.push(helpRange[i])
    } 

    if(!check.includes(to_time_value)){
      to_time_value = check[0]
      setTimeTo(to_time_value)
    }
    setToHourRange(check)
    setBusiness(prevBusiness => ({
      ...prevBusiness,
      business_hours: {
        time_from: value,
        time_to: to_time_value
      }
    }))
  }

  const handleEnd = (e) => {
    e.preventDefault()
    const { value } = e.target
    setTimeTo(value)
    setBusiness(prevBusiness => ({
      ...prevBusiness,
      business_hours: {
        time_from: timeFrom,
        time_to: value
      }
    }))
  }

  const handleProcessUpdateBusiness = (e) => {
    e.preventDefault()
    setDisableBtn(true)
    let url = '/api/v3/update_business'
    let params = {
      request_reason_optional: business?.request_reason_optional,
      invite_all_default: business?.invite_all_default,
      can_instructor_modify_locations: business?.can_instructor_modify_locations,
      manager_request_gate: business?.manager_request_gate,
      mrg_days: business?.mrg_days.toString(),
      second_skill_enabled: business?.second_skill_enabled,
      send_auto_mail_to_secondary_instructors: business?.send_auto_mail_to_secondary_instructors,
      show_responsibility_text: business?.show_responsibility_text,
      requesting_instructor_notifications: business?.requesting_instructor_notifications,
      availability_feature: business?.availability_feature,
      business_hours: business?.business_hours,
      resource_hub: business?.resource_hub
    }
    axiosRequest(url, 'POST', params, 'data').then((result) => {
      if(result.status == 200){
        setAlert(true, 'success', result?.success)
        setTimeout(()=>{
          setAlert(false, '', '')
          setDisableBtn(false)
          window.location.reload(true);
        }, 3000);
      } else {
        setAlert(true, 'danger', response.error)
        setTimeout(()=>{
          setAlert(false, '', '')
          setDisableBtn(false)
        }, 3000);
      }
    })
  }

  return(
    <Fragment>
      <div className='bg-white rounded-md shadow px-6 mb-10'>
        <header className='py-7 flex items-center gap-2'>
          <h1 className="text-2xl font-semibold leading-tight text-gray-900">Sub Policy Settings</h1>
          <TooltipComponent componentId={'sub-policy-settings-tooltip-message'} classNames={'w-4 h-4'} tooltipMessage={'This section can only be edited by the Account Owner.'}/>
        </header>
        <div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-center py-5'>
          <label className="block text-sm font-medium text-gray-500">Reason for request required
          </label>
          <div className='mt-1 sm:mt-0 sm:col-span-2 flex gap-2 items-center'>
            <Switch
              id={`request_reason_optional-1`}
              checked={!business?.request_reason_optional}
              name="request_reason_optional"
              onChange={(e) => handleSwitch(e, 'request_reason_optional')}
              className={`${!business?.request_reason_optional ? 'bg-gray-900' : 'bg-gray-200'} relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none ${disabledPage ? 'cursor-not-allowed opacity-50' : ''}`}
              disabled = {disabledPage}
            >
              <span className="sr-only">Use setting</span>
              <span
                aria-hidden="true"
                className={`${!business?.request_reason_optional ? 'translate-x-5' : 'translate-x-0'} pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200`}
              />
            </Switch>
            <label className={`ml-2 text-sm ${disabledPage ? 'text-gray-400' : ''}`} htmlFor="request_reason_optional-1">
              {!business?.request_reason_optional ? 'Yes' : 'No'}
            </label>
          </div>
        </div>
        <div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-center border-t border-gray-200 py-5'>
          <label className="block text-sm font-medium text-gray-500">
            Do you want to restrict instructors from sending personal invites?
          </label>
          <div className='mt-1 items-center sm:mt-0 sm:col-span-2 flex gap-2'>
            <Switch
              id={`invite_all_default-1`}
              checked={business?.invite_all_default}
              name="invite_all_default"
              onChange={(e) => handleSwitch (e, "invite_all_default")}
              className={`${business?.invite_all_default ? 'bg-gray-900' : 'bg-gray-200'} relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none ${disabledPage ? 'cursor-not-allowed opacity-50' : ''}`}
              disabled = {disabledPage}
            >
              <span className="sr-only">Use setting</span>
              <span
                aria-hidden="true"
                className={`${business?.invite_all_default ? 'translate-x-5' : 'translate-x-0'} pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200`}
              />
            </Switch>
            <label className={`text-sm ml-2 ${disabledPage ? 'text-gray-400' : ''}`} htmlFor="invite_all_default-1">
              {business?.invite_all_default ? 'Yes' : 'No'}
            </label>
          </div>
        </div>
        {(!business?.clubready_integration && !business?.fisikal_integration) &&
          <div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-center border-t border-gray-200 py-5'>
            <label className="block text-sm font-medium text-gray-500">
              Do you want instructors to be able to choose the locations they sub at?
            </label>
            <div className='mt-1 items-center sm:mt-0 sm:col-span-2 flex gap-2'>
              <Switch
                id={`can_instructor_modify_locations-1`}
                checked={business?.can_instructor_modify_locations}
                name="can_instructor_modify_locations"
                onChange={(e) => handleSwitch (e, "can_instructor_modify_locations")}
                className={`${business?.can_instructor_modify_locations ? 'bg-gray-900' : 'bg-gray-200'} relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none ${disabledPage ? 'cursor-not-allowed opacity-50' : ''}`}
                disabled = {disabledPage}
              >
                <span className="sr-only">Use setting</span>
                <span
                  aria-hidden="true"
                  className={`${business?.can_instructor_modify_locations ? 'translate-x-5' : 'translate-x-0'} pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200`}
                />
              </Switch>
              <label className={`text-sm ml-2 ${disabledPage ? 'text-gray-400' : ''}`} htmlFor="can_instructor_modify_locations-1">
                {business?.can_instructor_modify_locations ? 'Yes' : 'No'}
              </label>
            </div>
          </div>
        }
        <div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-center border-t border-gray-200 py-5'>
          <label className="block text-sm font-medium text-gray-500">
            Do you want to require time off approval (Manager Board feature) before users can submit a sub request?
          </label>
          <div className='mt-1 items-center sm:mt-0 sm:col-span-2 flex gap-2'>
            <Switch
              id={`manager_request_gate-1`}
              checked={business?.manager_request_gate}
              name="manager_request_gate"
              onChange={(e) => handleSwitch (e, "manager_request_gate")}
              className={`${business?.manager_request_gate ? 'bg-gray-900' : 'bg-gray-200'} relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none ${disabledPage ? 'cursor-not-allowed opacity-50' : ''}`}
              disabled = {disabledPage}
            >
              <span className="sr-only">Use setting</span>
              <span
                aria-hidden="true"
                className={`${business?.manager_request_gate ? 'translate-x-5' : 'translate-x-0'} pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200`}
              />
            </Switch>
            <label className={`text-sm ml-2 ${disabledPage ? 'text-gray-400' : ''}`} htmlFor="manager_request_gate-1">
              {business?.manager_request_gate ? 'Yes' : 'No'}
            </label>
          </div>
        </div>
        {(business?.manager_request_gate == "true" || business?.manager_request_gate == true) &&
          <div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-center border-t border-gray-200 py-5'>
            <label className="block text-sm font-medium text-gray-500">
              When do you want the Manager Board to be on?
            </label>
            <div className='mt-1 sm:mt-0 sm:col-span-2'>
              <select name="mrg_days" onChange={(e) => handleChange(e)} defaultValue={business?.mrg_days} className={`lg:w-80 form-select bg-white block w-full pl-3 pr-10 py-2 h-10 text-base border border-gray-300  sm:text-sm rounded-md shadow-sm ${disabledPage ? 'cursor-not-allowed opacity-50' : ''}`} disabled = {disabledPage}>
                <option value="">Select Weeks</option>
                {[1, 2, 3, 4, 5, 6, 7].map((item, i) =>
                  <option key={i} value={item.toString()}>{item}</option>
                )}
              </select>  
            </div>
          </div>
        }
        <div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-center border-t border-gray-200 py-5'>
          <label className="block text-sm font-medium text-gray-500">
            Do you want secondary skills?
          </label>
          <div className='mt-1 items-center sm:mt-0 sm:col-span-2 flex gap-2'>
            <Switch
              id={`second_skill_enabled-1`}
              checked={business?.second_skill_enabled}
              name="second_skill_enabled"
              onChange={(e) => handleSwitch (e, "second_skill_enabled")}
              className={`${business?.second_skill_enabled ? 'bg-gray-900' : 'bg-gray-200'} relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none ${disabledPage ? 'cursor-not-allowed opacity-50' : ''}`}
              disabled = {disabledPage}
            >
              <span className="sr-only">Use setting</span>
              <span
                aria-hidden="true"
                className={`${business?.second_skill_enabled ? 'translate-x-5' : 'translate-x-0'} pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200`}
              />
            </Switch>
            <label className={`text-sm ml-2 ${disabledPage ? 'text-gray-400' : ''}`} htmlFor="second_skill_enabled-1">
              {business?.second_skill_enabled ? 'Yes' : 'No'}
            </label>
          </div>
        </div>
        {(business?.second_skill_enabled == "true" || business?.second_skill_enabled == true) &&
          <div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-center border-t border-gray-200 py-5'>
            <label className="block text-sm font-medium text-gray-500">
              Do you want to automatically invite secondary skill instructors within 24 hours of class time?
            </label>
            <div className='mt-1 sm:mt-0 sm:col-span-2 flex gap-2'>
              <Switch
                id={`send_auto_mail_to_secondary_instructors-1`}
                checked={business?.send_auto_mail_to_secondary_instructors}
                name="send_auto_mail_to_secondary_instructors"
                onChange={(e) => handleSwitch (e, "send_auto_mail_to_secondary_instructors")}
                className={`${business?.send_auto_mail_to_secondary_instructors ? 'bg-gray-900' : 'bg-gray-200'} relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none ${disabledPage ? 'cursor-not-allowed opacity-50' : ''}`}
                disabled = {disabledPage}
              >
                <span className="sr-only">Use setting</span>
                <span
                  aria-hidden="true"
                  className={`${business?.send_auto_mail_to_secondary_instructors ? 'translate-x-5' : 'translate-x-0'} pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200`}
                />
              </Switch>
              <label className={`text-sm ml-2 ${disabledPage ? 'text-gray-400' : ''}`} htmlFor="send_auto_mail_to_secondary_instructors-1">
                {business?.send_auto_mail_to_secondary_instructors ? 'Yes' : 'No'}
              </label>
            </div>
          </div>
        }
        <div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-center border-t border-gray-200 py-5'>
          <label className="block text-sm font-medium text-gray-500">
            Do you want text throughout the tool letting the instructors know they are responsible for finding coverage?
          </label>
          <div className='mt-1 sm:mt-0 sm:col-span-2 flex gap-2 items-center'>
            <Switch
              id={`show_responsibility_text-1`}
              checked={business?.show_responsibility_text}
              name="show_responsibility_text"
              onChange={(e) => handleSwitch (e, "show_responsibility_text")}
              className={`${business?.show_responsibility_text ? 'bg-gray-900' : 'bg-gray-200'} relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none ${disabledPage ? 'cursor-not-allowed opacity-50' : ''}`}
              disabled = {disabledPage}
            >
              <span className="sr-only">Use setting</span>
              <span
                aria-hidden="true"
                className={`${business?.show_responsibility_text ? 'translate-x-5' : 'translate-x-0'} pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200`}
              />
            </Switch>
            <label className={`text-sm ml-2 ${disabledPage ? 'text-gray-400' : ''}`} htmlFor="show_responsibility_text-1">
              {business?.show_responsibility_text ? 'Yes' : 'No'}
            </label>
          </div>
        </div>
        <div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-center border-t border-gray-200 py-5'>
          <label className="block text-sm font-medium text-gray-500">
            Do you want us to notify the original instructor who needs to find a sub when someone accepts their sub request?
          </label>
          <div className='mt-1 sm:mt-0 sm:col-span-2 flex gap-2 items-center'>
            <Switch
              id={`requesting_instructor_notifications-1`}
              checked={business?.requesting_instructor_notifications}
              name="requesting_instructor_notifications"
              onChange={(e) => handleSwitch (e, "requesting_instructor_notifications")}
              className={`${business?.requesting_instructor_notifications ? 'bg-gray-900' : 'bg-gray-200'} relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none ${disabledPage ? 'cursor-not-allowed opacity-50' : ''}`}
              disabled = {disabledPage}
            >
              <span className="sr-only">Use setting</span>
              <span
                aria-hidden="true"
                className={`${business?.requesting_instructor_notifications ? 'translate-x-5' : 'translate-x-0'} pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200`}
              />
            </Switch>
            <label className={`text-sm ml-2 ${disabledPage ? 'text-gray-400' : ''}`} htmlFor="requesting_instructor_notifications-1">
              {business?.requesting_instructor_notifications ? 'Yes' : 'No'}
            </label>
          </div>
        </div>
        <div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-center border-t border-gray-200 py-5'>
          <label className="block text-sm font-medium text-gray-500">
            Activate Availability Feature
          </label>
          <div className='mt-1 sm:mt-0 sm:col-span-2 flex gap-2 items-center'>
            <Switch
              id={`availability_feature-1`}
              checked={business?.availability_feature}
              name="availability_feature"
              onChange={(e) => handleSwitch (e, "availability_feature")}
              className={`${business?.availability_feature ? 'bg-gray-900' : 'bg-gray-200'} relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none ${disabledPage ? 'cursor-not-allowed opacity-50' : ''}`}
              disabled = {disabledPage}
            >
              <span className="sr-only">Use setting</span>
              <span
                aria-hidden="true"
                className={`${business?.availability_feature ? 'translate-x-5' : 'translate-x-0'} pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200`}
              />
            </Switch>
            <label className={`text-sm ml-2 ${disabledPage ? 'text-gray-400' : ''}`} htmlFor="availability_feature-1">
              {business?.availability_feature ? 'Yes' : 'No'}
            </label>
          </div>
        </div>
        {(business?.availability_feature == "true" || business?.availability_feature == true) &&
          <div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start py-3'>
            <label className="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">
            </label>
            <div className='mt-1 sm:mt-0 sm:col-span-2 flex gap-2 items-center'>
              <label className='text-sm font-medium text-gray-500 py-1.5'>From</label>
              <div className="lg:w-32 relative messenger-box py-1.5 mr-2">
                <select 
                  name = "time_from"
                  onChange={(e) => handleStart(e)} 
                  value={timeFrom} 
                  className={`form-select bg-white block w-full pl-3 pr-5 py-2 h-9 text-base border border-gray-300  sm:text-sm rounded-md shadow-sm ${disabledPage ? 'cursor-not-allowed opacity-50' : ''}`}
                  disabled = {disabledPage}
                >
                    {hourRange.map((fromHour, i) =>
                      <option key =  {i} value = {fromHour}>{fromHour}</option>
                    )}
                </select>
              </div>
              <label className='text-sm font-medium text-gray-500 py-1.5'>To</label>
              <div className="lg:w-32 relative messenger-box py-1.5">
                <select 
                  name = "time_to"
                  onChange={(e) => handleEnd(e)}
                  value={timeTo} 
                  className={`form-select bg-white block w-full pl-3 pr-5 py-2 h-9 text-base border border-gray-300  sm:text-sm rounded-md shadow-sm ${disabledPage ? 'cursor-not-allowed opacity-50' : ''}`}
                  disabled = {disabledPage}
                >
                    {toHourRange.map((toHour, i) =>
                      <option key =  {i} value = {toHour}>{toHour}</option>
                    )}
                </select>
              </div>
            </div>
          </div>
        }
        <div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-center border-t border-gray-200 py-5'>
          <label className="block text-sm font-medium text-gray-500">
            Activate Resource Hub Feature
          </label>
          <div className='mt-1 sm:mt-0 sm:col-span-2 flex gap-2 items-center'>
            <Switch
              id={`resource_hub-1`}
              checked={business?.resource_hub}
              name="resource_hub"
              onChange={(e) => handleSwitch (e, "resource_hub")}
              className={`${business?.resource_hub ? 'bg-gray-900' : 'bg-gray-200'} relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none ${disabledPage ? 'cursor-not-allowed opacity-50' : ''}`}
              disabled = {disabledPage}
            >
              <span className="sr-only">Use setting</span>
              <span
                aria-hidden="true"
                className={`${business?.resource_hub ? 'translate-x-5' : 'translate-x-0'} pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200`}
              />
            </Switch>
            <label className={`text-sm ml-2 ${disabledPage ? 'text-gray-400' : ''}`} htmlFor="resource_hub-1">
              {business?.resource_hub ? 'Yes' : 'No'}
            </label>
          </div>
        </div>
        <div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start border-t border-gray-200 py-5'>
          <label className="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2"></label>
          <div className='mt-1 sm:mt-0 sm:col-span-2'>
            <div>
              <button className={`text-white inline-block px-6 py-2 ml-auto border shadow rounded-md bg-dark-blue ${disableBtn || disabledPage ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'}`} onClick={(e) => handleProcessUpdateBusiness(e)} disabled={disableBtn || disabledPage}>
                <div className="flex items-center justify-center gap-1 relative">
                  {disableBtn && 
                    <div className="flex items-center -left-5 justify-center absolute">
                      <svg className="animate-spin h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                    </div>
                  }
                  <div className='text-white text-base'>Update Sub Policy</div>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}