import React, { Fragment, useEffect, useState } from "react";
// import LocationDropDownComponent from './locationDropDownComponent';
import LocationDropDownComponent from '../../basicBusinessSelectClassComponents/locationSelectorComponent/locationDropDownComponent';
import { axiosGetRequest } from './../../../../../subRequests/helpers/helpers';
import { getSortedData, getTheClassesHash } from './../../../../helpers';
export default function LocationComponent(props) {
  const { newSubRequest, setNewSubRequest } = props
  const [ instructorLocations, setInstructorLocations ] = useState([])
  const [ selectedLocation, setSelectedLocation ] = useState(newSubRequest?.locationId || '')
  const [ selectedLocationName, setSelectedLocationName ] = useState('')
  const [ locationOptions, setLocationOptions ] = useState([])
  const [ loading, setLoading ] = useState(false);


  useEffect(() => {
    fetchInstructorLocations()
  }, [newSubRequest?.requestingInstructor])

  const fetchInstructorLocations = () => {
    if(newSubRequest?.requestingInstructor?.id == '') return
    setLoading(true);
    axiosGetRequest(`/api/v2/instructor_locations?requesting_instructor_id=${newSubRequest?.requestingInstructor?.id}`).then(result => {
      setLoading(false);
      if(result?.status == 200){
        setInstructorLocations(getSortedData(result?.results))
        setLocationOptions(getTheClassesHash(result?.results))
        if (selectedLocationName == '' && selectedLocation !== '') {
          setSelectedLocationName(result?.results.find(loc => loc.id == selectedLocation)?.name)
        }
        if(result?.results?.length == 1){
          handleSaveLocation(result?.results[0].id, result?.results[0].name, 0)
        }
      }
    })
  }

  const handleSaveLocation = (locationId, locationName, i) => {
    setSelectedLocation(locationId)
    setNewSubRequest(prevNewSubRequest => ({
      ...prevNewSubRequest,
      locationId: locationId
    }))
    setSelectedLocationName(locationName)
  }

  return(
    <Fragment>
      <div className="flex flex-col gap-2 filter-column justify-end">
        <div className="flex text-base md:text-lg text-black font-semibold">
          Location
        </div>
        <LocationDropDownComponent
          i={0}
          instructorLocations={instructorLocations}
          selectedLocationId={selectedLocation}
          selectedLocationName={selectedLocationName}
          handleSaveLocation={handleSaveLocation}
          locationOptions={locationOptions}
          loading={loading}
        />
      </div>
    </Fragment>
  )
}