import React, { Fragment, useState, useEffect, useCallback, useRef } from "react";
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/outline';
import { SearchIcon } from './../../../assets';
import { hideItems, chekLocationNameCount, handleSaveFilters, axiosGetRequest } from './../../../helpers/helpers';
import { debounce } from "lodash";
import LoadingIcon from './../../../assets/loadingIcon'

export default function ClassesFilterComponent(props) {
  const { selectedClasses, handleSelectClasses, user } = props
  const menuButtonRef = useRef(null);
  const [ searchValue, setSearchValue ] = useState('')
  const [ allClassesList, setAllClassesList ] = useState([])
  const [ classesList, setClassesList ] = useState([])
  const pluckSelectedClsIds = (selectedClses) => {
    return(selectedClses?.map((cls) => cls?.id))
  }
  const [ selectedClsHash, setSelectedClsHash ] = useState(selectedClasses || [])
  const [ selectedClassesIds, setSelectedClassesIds ] = useState(pluckSelectedClsIds(selectedClasses || []))
  const [ showLoading, setShowLoading ] = useState(false);

  useEffect(() => {
    setSelectedClsHash(selectedClasses)
    setSelectedClassesIds(pluckSelectedClsIds(selectedClasses || []))
  }, [selectedClasses])

  const handleApplyFilter = () => {
    handleSelectClasses(selectedClsHash)
    let classState = selectedClsHash?.length > 0 ? 'some_selected' : 'no_selected'
    handleSaveFilters(selectedClsHash, classState, 'class', user?.id)
    menuButtonRef.current.click()
  }

  const handleClassesSearch = (e) => {
    let val = e.target.value
    if (e.key === ' ') {
      e.preventDefault();
      val += ' ';
    }
    setSearchValue(val)
    searchClasses(val)
  }

  const searchClasses = useCallback(
    debounce(val => {
      if(val.trim() == '') {
        setClassesList([]);
        setAllClassesList([])
      }else{
        handleSearchClasses(val.trim())
      }
    }, 800),
    [selectedClassesIds]
  );

  const handleSearchClasses = (text) => {
    let url = `${window._sub_board_url}/api/v3/sub_overview/all_requests_filters?classes_search=${text}`
    setShowLoading(true)
    axiosGetRequest(url).then((result) => {
      setShowLoading(false)
      if(result.status == 200){
        setAllClassesList(result?.classes)
        setClassesList(result?.classes.filter((cls) => !selectedClassesIds.includes(cls?.id)));
      }
    })
  }

  const processSelectClasses = (e, clss) => {
    let clsId = parseInt(clss?.id)
    var __FOUND = selectedClassesIds.includes(clsId)
    if(e.target.checked){
      if(!__FOUND){
        let newClassIds = [...selectedClassesIds, clsId]
        setSelectedClassesIds(newClassIds)
        let newCls = {
          id: clsId,
          name: clss?.name
        }
        setSelectedClsHash([...selectedClsHash, newCls])
        fillUnmatchedClsInList(newClassIds)
      }
    }else{
      if(__FOUND){
        let newClassIds = selectedClassesIds.filter((id) => id !== clsId)
        setSelectedClassesIds(newClassIds)
        setSelectedClsHash(selectedClsHash.filter((cls) => cls?.id !== clsId))
        fillUnmatchedClsInList(newClassIds)
      }
    }
  }

  const fillUnmatchedClsInList = (classes) => {
    if(classes?.length > 0){
      let newClasses = allClassesList.filter((cls) => !classes.includes(cls?.id))
      setClassesList(newClasses)
    }else{
      setClassesList(allClassesList)
    }
  }

  const classesNames = () => {
    let names = []
    let classes = selectedClsHash
    let ctr = 0
    for(let i = 0; i < classes?.length; i++){
      if(ctr < 2){
        names.push(classes[i].name)
        ctr++
      }
      else{
        break;
      }
    }
    return chekLocationNameCount(names.join(", "))
  }

  const processFillBackFilter = () => {
    setSelectedClsHash(selectedClasses)
    setSelectedClassesIds(pluckSelectedClsIds(selectedClasses || []))
    setAllClassesList([]);
    setClassesList([]);
    setSearchValue('');
  }

  useEffect(() => {
    if(selectedClassesIds?.length > 0){
      $('.classesspecial_drop-down_button').addClass('filter-apply-border')
    }else {
      $('.classesspecial_drop-down_button').removeClass('filter-apply-border')
    }
  }, [selectedClassesIds])

  const setClassesDropDownRef = (node) => {
    if(node){
      $('.classesspecial_drop-down_button').addClass('filter-apply-border')
    }else if (node === null) {
      if(selectedClassesIds?.length == 0) $('.classesspecial_drop-down_button').removeClass('filter-apply-border')
      processFillBackFilter()
    }
  }

  return(
    <Fragment>
      <div className="relative filters_box">
        <Menu as="div" className="relative block text-left">
          <div>
            <Menu.Button className="flex justify-center w-full rounded-xl pl-3 pr-2 py-2.5 bg-gray-custom-50 text-sm text-gray-900 focus:outline-nonefocus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500 classesspecial_drop-down_button border-2 border-transparent need-border font-medium" ref={menuButtonRef}>
              {selectedClsHash?.length === 0 && <p className = "text-gray-900">All Classes</p>
              }
              {selectedClsHash?.length > 0 &&
                <React.Fragment>
                  {(selectedClsHash?.length === 1) && 
                    <p className = "text-gray-900">{classesNames()}</p>
                  }
                  {(selectedClsHash?.length > 1) && 
                    <div className = "flex text-gray-900 items-center">
                      <div className={`text-gray-900 truncate max-wc-160`}>{classesNames()}</div>
                      {(selectedClsHash?.length - classesNames()?.split(',')?.length) > 0 && <p className="text-xs ml-1">+{selectedClsHash?.length - classesNames()?.split(',')?.length}</p>}
                    </div>
                  }
                </React.Fragment>
              }
              <ChevronDownIcon className="ml-auto text-gray-900 h-5 w-5" aria-hidden="true" />
            </Menu.Button>
          </div>
          <Transition
            as={Fragment}
            // show={true}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95">
            <Menu.Items ref={setClassesDropDownRef} className="origin-top-right z-30 right-0 w-full rounded-2xl bg-white ring-2 ring-gray-500 focus:outline-none mt-2 absolute">
              <div className="py-2 h-60 max-h-60 overflow-y-auto">
                <div className='bg-white rounded-2xl'>
                  <div className='bg-gray-custom-50 rounded-2xl py-1.5 px-3.5 mx-5 my-1'>
                    <div className='flex items-center'>
                      <SearchIcon classNames={'h-5 w-5 text-gray-400'}/>
                      <input type="text" className="ml-1.5 focus:outline-none w-full bg-transparent" placeholder="Search" value={searchValue} onChange={(e) => handleClassesSearch(e)} onKeyDown={handleClassesSearch}></input>
                    </div>
                  </div>
                </div>
                {selectedClsHash?.length > 0 &&
                  <div className=''>  
                    {selectedClsHash.map((cls, i) => 
                      <div className="py-1" key = {i}>
                        <label className="flex px-5 text-gray-900 text-sm mb-2">
                          <input type="checkbox" className="w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300 mr-2 mt-0.5 cursor-pointer" onChange={(e) => processSelectClasses(e, cls)} value={cls?.id} checked={selectedClassesIds.includes(cls?.id)}/>
                          <div className='flex items-center gap-2 cursor-pointer'>{cls?.name}</div>
                        </label>
                      </div>
                    )}
                    <div className="px-5 mb-1">
                      <hr />
                    </div>
                  </div>
                }
                <div className=''>  
                  {classesList.map((cls, i) => 
                    <div className="py-1" key = {i}>
                      <label className="flex px-5 text-gray-900 text-sm mb-2">
                        <input type="checkbox" className="w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300 mr-2 mt-0.5 cursor-pointer" onChange={(e) => processSelectClasses(e, cls)} value={cls?.id} checked={selectedClassesIds.includes(cls?.id)}/>
                        <div className='flex items-center gap-2 cursor-pointer'>{cls?.name}</div>
                      </label>
                    </div>
                  )}
                  {classesList?.length == 0 && ((selectedClsHash?.length == 0) || (selectedClsHash?.length > 0 && searchValue.trim() == '')) && !showLoading &&
                    <div className={`flex text-sm text-gray-700 items-center text-center justify-between px-5 py-2 ${(classesList?.length == 0 && selectedClsHash?.length == 0) ? 'h-44' : ''}`}>
                      Search for a class name to filter
                    </div>
                  }
                  {showLoading &&
                    <div className={`flex items-center justify-center px-5 py-2 ${(classesList?.length == 0 && selectedClsHash?.length == 0) ? 'h-44' : ''}`}>
                      <LoadingIcon />
                    </div>
                  }
                </div>
              </div>
              <div className="flex items-center p-2 border-t">
                <div className="flex justify-center items-center w-full px-3 gap-3">
                  <div className=''>
                    <a className='bg-dark-blue text-white text-sm rounded-md py-2 px-4 font-medium h-9 block cursor-pointer' onClick={(e) => handleApplyFilter()}>Apply</a>
                  </div>
                </div>
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    </Fragment>
  )
}