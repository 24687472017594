import React, { Fragment, Item } from 'react'
import { Button } from 'react-bootstrap';
import { Accordion, Card } from "react-bootstrap";
import { Switch } from '@headlessui/react'
import { getSudomain, setTitle } from './../helpers.js'
import { uploadDefaultThumbnail, deleteDefaultThumbnail } from "../settings-v4/account/helpers"
import { Dialog, Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon, ExclamationIcon, XIcon, TrashIcon } from '@heroicons/react/outline'
import { getFilterSkillsOptions, 
	getResourceData, 
	destroyFile, 
	saveFile, 
	removeExtensionFromName, 
	snakeCase, 
	getName,
	ResourceThumbnailMinHeight, ResourceThumbnailMinWidth, ResourceThumbnailMaxHeight, ResourceThumbnailMaxWidth	
 } from './helpers.js'
import { debounce, IsUndefined } from "lodash";
import Cookies from 'js-cookie';
import { Link, Redirect } from "react-router-dom";
import Select from "react-select";

export default class Publish extends React.Component {
	constructor(props){
		super(props);
		this.state = {
			enabled: false,
			fileName: '',
			regions_and_locations: [],
			locations: [],
			skills: [],
			visibilities: [
				"All Staff",
				"BA's only",
				"BA's & RA's only",
				"BA's RA's & FM's only" 
			],
			show_others: null,
			alert_message: '',
			alert_type: '', 
			show: false,
			loaded: false, 
			selectedSkills: [],
			selectedLocations: [],
			selectedRegions: [],
			selectedVisibility: null,
			folderPathNames: '',
			folderName: null,
      path: '',
			itemToUpdate: [],
			pageState: '',
			showDeleteFileModal: false,
			deleted: false,
			uploaded: false,
			submitted: false,
			showNotifyAudiencePopup: false,
			oldSelectedSkills : [], 
			oldSelectedLocations: [],
			oldSelectedVisibility: null,
			oldFileName: '',
			onlyAccessSpecifierEdit: false,
			//catch all skills/locations selected
			allSkillsSelected: false,
			allLocationsSelected: false,
			thumbnailUploading: false,
			thumbnailMetadata: {},
			attachment: null,
			deleteThumbnailPopupOpen: false,
			foldersList: [{value: "All", label: "Move to Home"}],
			selectedDestinationFolderlist: {},
			defaultPreviewImg: null,
			saveType: 'publish'
		}
		this.thumbnailRef = React.createRef();

	}

	componentDidMount = () => {
		this.checkAvailability()
		this.props.setCurrent('Resource Hub')
    setTitle('resource_hub')
		if(this.props.pageLoaded){
			this.getAll()
		}
		// this.getfolderName()
  }

	checkAvailability = () => { 
    const { business, user } = this.props   
    if (Object.keys(business).length != 0) {
      if(business?.resource_hub === false){
        let role = snakeCase(user?.role)        
        if(role){
          let url = `/business_user#/${getName(role)}_home`
          window.location.href = url
        }        
      }
    }
  }

	getfolderName = () => {
    let folderPath = location.pathname?.split('/')
    let data = []
		let files = location.pathname?.split('/')
    let file = files.pop()
    for(let i = 0; i < folderPath?.length-1; i++){
      data.push(folderPath[i])
    }

		let state = ''
		switch (data[data?.length-2]) {
			case 'view-resource-detail':
				state = 'Edit'
				break;
			case 'add-file':
				state = 'Upload'
				break;
			default:
				state = 'Upload'
				break;
		}
    
    let folderPathName = []
    for(let i = 0; i < folderPath?.length-1; i++){
      if(i > 2){
        folderPathName.push(folderPath[i])
      }
    }
		
    let folderName = folderPath[folderPath?.length-1]
    let folder_path = folderPathName.filter(function(ele){ 
			return !['folder', 'add-file', 'view-resource-detail', 'publish'].includes(ele); 
		}).join('/')
		let pathName = []
		for(let i = 0; i < folderPath?.length-1; i++){
			pathName.push(folderPath[i])
		}
    this.setState({
      folderPathNames: folderPathName.filter(function(ele){ 
				return !['folder', 'add-file', 'view-resource-detail', 'publish'].includes(ele); 
			}),
      folderName: folderName,
      path: pathName.join('/'),
			pageState: state
    })
		getResourceData(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), file, folder_path).then(      
			response => response.json()
		)
		.then(result => {
			if(result.status === 200){
				this.checkSelectedTags(result.link)
				this.setState({
					itemToUpdate: result.link,
          allData: result.resource_data,
					loaded: true
				})
			}else{
				this.setState({
					alert_message: result.error,
					alert_type: 'danger', 
					show: true,           
					loaded: true
				})
				this.setState({show:true},()=>{
          window.setTimeout(()=>{
            this.setState({show:false})
          },3000)
        });
			}
		})
  }

	checkSelectedTags = (item) => {
		if(item){
			let location_tags = []
			let skill_tags = []
			// if all locations selected
			if(Boolean(item.all_locations)){
				for(let i = 0; i < this.state.locations?.length; i++){
					location_tags.push(this.state.locations[i].id)
				}
			}else{
				if(item.location_tags?.length > 0){
					for(let i = 0; i < item.location_tags?.length; i++){
						location_tags.push(item.location_tags[i].id)
					}
				}
			} 
			//if all skills selected
			if(Boolean(item.all_skills)){
				for(let i = 0; i < this.state.skills?.length; i++){
					skill_tags.push(this.state.skills[i].id)
				}
			}else {
				if(item.skill_tags?.length > 0){
					for(let i = 0; i < item.skill_tags?.length; i++){
						skill_tags.push(item.skill_tags[i].id)
					}
				}
			}

			let { regions_and_locations } = this.state;
			let regions = regions_and_locations;
			let locations = location_tags
			let selected_regions = []
			if(regions?.length > 0){
				for (let i = 0; i < regions?.length; i++){
					let region = regions[i]
					if(region){
						let location_ids = region?.locations?.map(function(a) {return a.id;});
						let checker = (arr, target) => target.every(v => arr.includes(v));
						let equal = checker(locations, location_ids)
						let val = region.region?.name

						let { selectedRegions } = this.state;
						let regions = selectedRegions
						var __FOUND = regions.indexOf(val) > -1;
						if(equal){
							if(!__FOUND){
								selected_regions.push(val)
							}
						}else{
							if(__FOUND){
								selected_regions = selected_regions.filter((tag, index) => tag !== val)
							}
						}
					}
				}
			}
			this.setState({
				selectedSkills: skill_tags, 
				oldSelectedSkills : skill_tags,
				selectedLocations: location_tags, 
				oldSelectedLocations: location_tags,
				selectedRegions: selected_regions,
				selectedVisibility: item.visitors,
				oldSelectedVisibility: item.visitors,
				fileName: removeExtensionFromName(item.name),
				oldFileName: removeExtensionFromName(item.name),
				enabled: item.comments_show
			})
		}
	}

	checkPrevUrl = (index) => {
    let folderPathName = this.state.folderPathNames
    let path = []
    for(let i = 0; i < folderPathName?.length; i++){
      if(i <= index){
        path.push(folderPathName[i])
      }
    }
    return path.join('/')
  }

	getAll = () => {

		getFilterSkillsOptions(Cookies.get(`currentUser.${getSudomain()}`), getSudomain()).then(      
			response => response.json()
		)
		.then(result => {
			if(result.status === 200){
				let regions_and_locations = result.regions_and_locations.sort((a, b) => a.region.name.localeCompare(b.region.name, 'es', {sensitivity: 'base'}))
				this.setState({
					regions_and_locations: regions_and_locations,
					locations: result.locations,
					skills: result.skills,
					show_others: result.show_others,
					visibilities: result.visibilty_array,
					// loaded: true
				})
				this.getfolderName()
			}else{
				this.setState({
					alert_message: result.error,
					alert_type: 'danger', 
					show: true,           
					loaded: true
				})
				this.setState({show:true},()=>{
          window.setTimeout(()=>{
            this.setState({show:false})
          },3000)
        });
			}
		})

	}

	componentWillUnmount() {
    this.setState = (state,callback)=>{
      return;
    };
  }

	handleSwitch = () => {
		let enabled = this.state.enabled
		this.setState({enabled: !enabled})
	}

	handleFileNameChange = (e) => {
		this.setState({fileName: e.target.value})
	}

	checkSelectedSkillsName = (id) => {
		let { skills } = this.state
		let skillName = []
		for(let i = 0; i < skills?.length; i++){
			if(id === skills[i].id){
				let check = skills[i].name.charAt(0).toUpperCase() + skills[i].name.slice(1);
				skillName.push(check)
			}
		}
	
		return skillName.join(', ')
	}

	selectAllSkills = () => {
    let check = this.state.skills?.length === this.state.selectedSkills?.length
    return check
  }

	handleSelectAllSkills = (e) => {
		if(e.target.checked){
			let { skills } = this.state;
      let selected_skills = []
      for(var i=0; i<skills.length;i++){ 
				selected_skills.push(skills[i]['id'])    
      }
      this.setState({
        selectedSkills: selected_skills,
      })
    }else{
      this.setState({
        selectedSkills: []
      })
    }
	}

	handleSelectSkills = (e) => {
		let val = parseInt(e.target.value)
    var __FOUND = this.state.selectedSkills.includes(val)
    if(e.target.checked){
      if(!__FOUND){
        let newSkills = [...this.state.selectedSkills, val]
        this.setState(state => ({ selectedSkills: newSkills }));
				
      }
    }else{
      if(__FOUND){
        const { selectedSkills } = this.state;
        let newSkills = selectedSkills.filter((tag, index) => tag !== val)  
        this.setState({
          selectedSkills: newSkills        
        }); 
			
      }      
    }
	}

	selectedAllRegLoc = () => {
    let check = this.state.selectedRegions?.length === this.state.regions_and_locations?.length
    return check
  }

	selectedAllRegionsLocations = (e) => {
    if(e.target.checked){
			const { regions_and_locations } = this.state;
      let all = regions_and_locations
      let selected_regions = []
      let selected_locations = []
      for(var i=0; i<all.length;i++){     
        selected_regions.push(all[i]['region']['name'])
        for(var j=0; j<all[i]['locations'].length; j++){
          selected_locations.push(all[i]['locations'][j]['id'])
        }
      }
      this.setState({
        selectedLocations: selected_locations,
        selectedRegions: selected_regions
      })
    }else{
      this.setState({
        selectedRegions: [],
        selectedLocations: []
      })
    }
  }

  selectRegions = (e, region) => {
    let val = e.target.value
		const { selectedRegions } = this.state;
    let regions = selectedRegions
   
    var __FOUND = regions.indexOf(val) > -1;
    var result = region.locations.map(function(a) {return a.id;});
		const { selectedLocations } = this.state;
    let selected_locations = selectedLocations;
    
    if(e.target.checked){
      if(!__FOUND){
        this.setState(state => ({ selectedRegions: [...state.selectedRegions, val] }));
        let final_result = []
        for(let i = 0; i < result?.length; i++){
          if(!selected_locations.includes(result[i])){
            final_result.push(result[i])
          }
        }
        selected_locations = Array.prototype.push.apply(selected_locations, final_result)
      }
    }else{
      if(__FOUND){
        
        const { selectedRegions } = this.state;
        this.setState({
          selectedRegions: selectedRegions.filter((tag, index) => tag !== val)          
        });
        selected_locations = selected_locations.filter(item => !result.includes(item));
        this.setState({
          selectedLocations: selected_locations
        })
      }
    }
  }

  selectLocation = (e, region) => {
    let val = parseInt(e.target.value)
    var __FOUND = this.state.selectedLocations.includes(val)
    if(e.target.checked){
      if(!__FOUND){
        let newlocs = [...this.state.selectedLocations, val]
        this.setState(state => ({ selectedLocations: newlocs }));
        this.checkRegionVisibility(region, newlocs)
      }
    }else{
      if(__FOUND){
        const { selectedLocations } = this.state;
        let newLocs = selectedLocations.filter((tag, index) => tag !== val)  
        this.setState({
          selectedLocations: newLocs        
        });
        this.checkRegionVisibility(region, newLocs)  
      }      
    }
  }

  checkRegionVisibility = (region, locations) =>{
    let location_ids = region.locations.map(function(a) {return a.id;});
    let checker = (arr, target) => target.every(v => arr.includes(v));
    let equal = checker(locations, location_ids)
    let val = region.region?.name

    let regions = this.state.selectedRegions
    var __FOUND = regions.indexOf(val) > -1;
    if(equal){
      if(!__FOUND){
        this.setState(state => ({ selectedRegions: [...state.selectedRegions, val] }))
      }
    }else{
      if(__FOUND){
        
        const { selectedRegions } = this.state;
        this.setState({
          selectedRegions: selectedRegions.filter((tag, index) => tag !== val)          
        });
      }
    }
  }

	chekLocationNameCount = (name) => {
		let shortName = []
		for(let i = 0; i < name?.length; i++){
			if(i < 20){
				shortName.push(name[i])
			}else{
				shortName.push('...')
				break
			}
		}
	
		return shortName.join('')
	}

	locationName = (id) => {
    let locations = this.state.locations
    let locationName = []
    for(let i = 0; i < locations?.length; i++){
      if(id === locations[i].id){
				let check = locations[i].name.charAt(0).toUpperCase() + locations[i].name.slice(1);
        locationName.push(check)
      }
    }
    return locationName.join(", ")
  }

	handleSelectVisibility = (e) => {
		if(e.target.checked){
			this.setState({selectedVisibility: e.target.value})
    }
    else{
      this.setState({selectedVisibility: ""})
    }	
	}

	removeSelectedSkill = (id) => {
		let selectedSkills = this.state.selectedSkills
		this.setState({
			selectedSkills: selectedSkills.filter(function(ele){ 
				return ele !== id;
			})
		})
	}

	removeSelectedLocation = (id) => {
		let selectedLocations = this.state.selectedLocations
		let regions_and_locations = this.state.regions_and_locations
		let region = regions_and_locations.filter(function(ele){ 
			return ele.locations.some(r=> r?.id === id)
		})
		let newLoc = selectedLocations.filter(function(ele){ 
			return ele !== id;
		})
		this.checkRegionVisibility(region[0], newLoc)
		this.setState({
			selectedLocations: newLoc
		})
	}

	showDocumentImage = (type) => {
		let path = ''
		if(type?.split('/')[0] === 'document'){
			if(type?.split('/')[1] === 'pdf'){
				path = '/assets/pdf.png'
			}
			else if (type?.split('/')[1] === 'docx' || type?.split('/')[1] === 'doc'){
				path = '/assets/docx-file.png'
			}
			else if (type?.split('/')[1] === 'pptx' || type?.split('/')[1] === 'ppt'){
				path = '/assets/powerpoint.png'
			}
			else if (type?.split('/')[1] === 'xlsx' || type?.split('/')[1] === 'xls') {
				path ='/assets/xls.png'
			}
			else if (type?.split('/')[1] === 'csv' || type?.split('/')[1] === 'csv') {
				path ='/assets/csv.png'
			}
			else{
				path = '/assets/img_default_files.png'
			}
		}
		else{
			path = '/assets/img_default_files.png'
		}
		return path
	}

	chooseIconName = (type) => {
		let checkType = type?.split('/')[0]
		switch(checkType){
			case 'document' :
				let checkExtention = type?.split('/')[1]
				switch(checkExtention){
					case 'docx' :
						return ('docx')
					case 'pdf' :
						return ('docx')
					case 'ppt' :
						return ('ppt')
					case 'pptx' :
						return ('ppt')
					case 'xcl' :
						return ('xcl')
					case 'csv' :
						return ('xcl')
					default:
						return ('docx')
				}
			case 'image':
				return('img')
			case 'video':
				return('video')
			case 'audio':
				return('aiff')
			default:
				return('')
		}
	}

	chooseIcon = (type) => {
		let checkType = type?.split('/')[0]
		switch(checkType){
			case 'document' :
				let checkExtention = type?.split('/')[1]
				switch(checkExtention){
					case 'docx' :
						return (
							<svg xmlns="http://www.w3.org/2000/svg" className="text-white h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
								<path fillRule="evenodd" d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2H6a2 2 0 01-2-2V4z" clipRule="evenodd" />
							</svg>
						)
					case 'doc' :
						return (
							<svg xmlns="http://www.w3.org/2000/svg" className="text-white h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
								<path fillRule="evenodd" d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2H6a2 2 0 01-2-2V4z" clipRule="evenodd" />
							</svg>
						)
					case 'pdf' :
						return (
							<svg xmlns="http://www.w3.org/2000/svg" className="text-white h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
								<path fillRule="evenodd" d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2H6a2 2 0 01-2-2V4z" clipRule="evenodd" />
							</svg>
						)
					case 'ppt' :
						return(
							<svg xmlns="http://www.w3.org/2000/svg" className="text-white h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
								<path fillRule="evenodd" d="M3 3a1 1 0 000 2v8a2 2 0 002 2h2.586l-1.293 1.293a1 1 0 101.414 1.414L10 15.414l2.293 2.293a1 1 0 001.414-1.414L12.414 15H15a2 2 0 002-2V5a1 1 0 100-2H3zm11 4a1 1 0 10-2 0v4a1 1 0 102 0V7zm-3 1a1 1 0 10-2 0v3a1 1 0 102 0V8zM8 9a1 1 0 00-2 0v2a1 1 0 102 0V9z" clipRule="evenodd" />
							</svg>
						)
					case 'pptx' :
						return(
							<svg xmlns="http://www.w3.org/2000/svg" className="text-white h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
								<path fillRule="evenodd" d="M3 3a1 1 0 000 2v8a2 2 0 002 2h2.586l-1.293 1.293a1 1 0 101.414 1.414L10 15.414l2.293 2.293a1 1 0 001.414-1.414L12.414 15H15a2 2 0 002-2V5a1 1 0 100-2H3zm11 4a1 1 0 10-2 0v4a1 1 0 102 0V7zm-3 1a1 1 0 10-2 0v3a1 1 0 102 0V8zM8 9a1 1 0 00-2 0v2a1 1 0 102 0V9z" clipRule="evenodd" />
							</svg>
						)
					case 'xls' :
						return(
							<svg xmlns="http://www.w3.org/2000/svg" className="text-white h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
								<path fillRule="evenodd" d="M3 3a1 1 0 000 2v8a2 2 0 002 2h2.586l-1.293 1.293a1 1 0 101.414 1.414L10 15.414l2.293 2.293a1 1 0 001.414-1.414L12.414 15H15a2 2 0 002-2V5a1 1 0 100-2H3zm11 4a1 1 0 10-2 0v4a1 1 0 102 0V7zm-3 1a1 1 0 10-2 0v3a1 1 0 102 0V8zM8 9a1 1 0 00-2 0v2a1 1 0 102 0V9z" clipRule="evenodd" />
							</svg>
						)
					case 'xlsx' :
						return(
							<svg xmlns="http://www.w3.org/2000/svg" className="text-white h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
								<path fillRule="evenodd" d="M3 3a1 1 0 000 2v8a2 2 0 002 2h2.586l-1.293 1.293a1 1 0 101.414 1.414L10 15.414l2.293 2.293a1 1 0 001.414-1.414L12.414 15H15a2 2 0 002-2V5a1 1 0 100-2H3zm11 4a1 1 0 10-2 0v4a1 1 0 102 0V7zm-3 1a1 1 0 10-2 0v3a1 1 0 102 0V8zM8 9a1 1 0 00-2 0v2a1 1 0 102 0V9z" clipRule="evenodd" />
							</svg>
						)
					case 'csv' :
						return(
							<svg xmlns="http://www.w3.org/2000/svg" className="text-white h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
								<path fillRule="evenodd" d="M5 4a3 3 0 00-3 3v6a3 3 0 003 3h10a3 3 0 003-3V7a3 3 0 00-3-3H5zm-1 9v-1h5v2H5a1 1 0 01-1-1zm7 1h4a1 1 0 001-1v-1h-5v2zm0-4h5V8h-5v2zM9 8H4v2h5V8z" clipRule="evenodd" />
							</svg>
						)
					default:
						return (
							<svg xmlns="http://www.w3.org/2000/svg" className="text-white h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
								<path fillRule="evenodd" d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2H6a2 2 0 01-2-2V4z" clipRule="evenodd" />
							</svg>
						)
				}
				
			case 'aiff':
				return(
					<svg xmlns="http://www.w3.org/2000/svg" className="text-white h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
						<path d="M18 3a1 1 0 00-1.196-.98l-10 2A1 1 0 006 5v9.114A4.369 4.369 0 005 14c-1.657 0-3 .895-3 2s1.343 2 3 2 3-.895 3-2V7.82l8-1.6v5.894A4.37 4.37 0 0015 12c-1.657 0-3 .895-3 2s1.343 2 3 2 3-.895 3-2V3z" />
					</svg>
				)
			case 'image':
				return(
					<svg xmlns="http://www.w3.org/2000/svg" className="text-white h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
						<path fillRule="evenodd" d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z" clipRule="evenodd" />
					</svg>
				)
			case 'video':
				return(
					<svg xmlns="http://www.w3.org/2000/svg" className="text-white h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
						<path d="M2 6a2 2 0 012-2h6a2 2 0 012 2v8a2 2 0 01-2 2H4a2 2 0 01-2-2V6zM14.553 7.106A1 1 0 0014 8v4a1 1 0 00.553.894l2 1A1 1 0 0018 13V7a1 1 0 00-1.447-.894l-2 1z" />
					</svg>
				)
			case 'audio':
				return(
					<svg xmlns="http://www.w3.org/2000/svg" className="text-white h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
						<path d="M18 3a1 1 0 00-1.196-.98l-10 2A1 1 0 006 5v9.114A4.369 4.369 0 005 14c-1.657 0-3 .895-3 2s1.343 2 3 2 3-.895 3-2V7.82l8-1.6v5.894A4.37 4.37 0 0015 12c-1.657 0-3 .895-3 2s1.343 2 3 2 3-.895 3-2V3z" />
					</svg>
				)
			case 'ppt':
				return(
					<svg xmlns="http://www.w3.org/2000/svg" className="text-white h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
						<path fillRule="evenodd" d="M3 3a1 1 0 000 2v8a2 2 0 002 2h2.586l-1.293 1.293a1 1 0 101.414 1.414L10 15.414l2.293 2.293a1 1 0 001.414-1.414L12.414 15H15a2 2 0 002-2V5a1 1 0 100-2H3zm11 4a1 1 0 10-2 0v4a1 1 0 102 0V7zm-3 1a1 1 0 10-2 0v3a1 1 0 102 0V8zM8 9a1 1 0 00-2 0v2a1 1 0 102 0V9z" clipRule="evenodd" />
					</svg>
				)
			case 'xcl':
				return(
					<svg xmlns="http://www.w3.org/2000/svg" className="text-white h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
						<path fillRule="evenodd" d="M5 4a3 3 0 00-3 3v6a3 3 0 003 3h10a3 3 0 003-3V7a3 3 0 00-3-3H5zm-1 9v-1h5v2H5a1 1 0 01-1-1zm7 1h4a1 1 0 001-1v-1h-5v2zm0-4h5V8h-5v2zM9 8H4v2h5V8z" clipRule="evenodd" />
					</svg>
				)
			default:
				return(
					<svg xmlns="http://www.w3.org/2000/svg" className="text-white h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
						<path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd" />
					</svg>
				)
		}
	}

	backButtonUrl = () => {
		let folderPath = location.pathname?.split('/')
		let files = location.pathname?.split('/')
    let file = files.pop()
		let pathName = []
		let path = []
		for(let i = 0; i < folderPath?.length-1; i++){
			pathName.push(folderPath[i])
			if(i < folderPath?.length-2){
				path.push(folderPath[i])
			}
		}
		switch (pathName[pathName?.length-2]) {
			case 'view-resource-detail':
				path.push(file)
				break;
			case 'add-file':
				path.pop()
				break;
			default:
				break;
		}
		
		return path.join('/')
	}

	closeDeleteModal = (e) => {
		this.setState({
			showDeleteFileModal: false,
			// redirect: false,
			// editFolder: false,
		},()=>{
			window.setTimeout(()=>{
				// this.setState({editFolderName: '', editingValue: ''})
			},1000)
		})
	}

	processDeleteFile = (e) => {
		this.closeDeleteModal()
		// this.setState({filterDataLoaded: false})
		let fileId = this.state.itemToUpdate?.id
		let folder = this.state.folderPathNames.join('/')
		destroyFile(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), fileId, folder).then(      
			response => response.json()
		)
		.then(result => {
			if(result.status === 200){
				this.setState({
					alert_message: result.error,
					alert_type: 'success', 
					show: true,
				})
				this.setState({show:true},()=>{
          window.setTimeout(()=>{
            this.setState({show:false, deleted: true})
          },3000)
        });
			}else{
				this.setState({
					alert_message: result.error,
					alert_type: 'danger', 
					show: true,           
				})
				this.setState({show:true},()=>{
          window.setTimeout(()=>{
            this.setState({show:false})
          },3000)
        });
			}
		})
	}

	redirectToPrevFolder = () => {
		let folder = this.state.folderPathNames
		let path = ''
		if(folder?.length > 0){
			path = '/resource_hub/folder/'+folder?.join('/')
		}
		else if(folder?.length === 0){
			path = '/resource_hub'
		}
		
		return path
	}

	handlePublishFile = async (e, type) => {
		if(this.state.fileName === '' || this.state.selectedLocations.length === 0 ){
			this.setState({submitted: true})
			console.log("Name and locations should not be empty")
			return
		}
		const { itemToUpdate, oldSelectedSkills, oldSelectedLocations, oldSelectedVisibility, oldFileName, fileName, selectedSkills, selectedLocations, selectedVisibility } = this.state
		if(itemToUpdate?.drafted){
			this.setState({
				showNotifyAudiencePopup: true,
				onlyAccessSpecifierEdit: false
			})
		}else{
			if(oldFileName.trim() === fileName.trim() && (oldSelectedLocations.every(v => selectedLocations.includes(v)) && selectedLocations.every(v => oldSelectedLocations.includes(v))) && (oldSelectedSkills.every(v => selectedSkills.includes(v)) && selectedSkills.every(v => oldSelectedSkills.includes(v))) && oldSelectedVisibility === selectedVisibility){
				this.saveAll(e,type, false)
			}else{
				if((oldFileName.trim() === fileName.trim() && ((!selectedLocations.every(v => oldSelectedLocations.includes(v)) || !oldSelectedLocations.every(v => selectedLocations.includes(v))) || (!oldSelectedSkills.every(v => selectedSkills.includes(v)) || !selectedSkills.every(v => oldSelectedSkills.includes(v))) || oldSelectedVisibility !== selectedVisibility))){
					this.setState({
						showNotifyAudiencePopup: true,
						onlyAccessSpecifierEdit: true
					})
				}else{
					this.setState({
						showNotifyAudiencePopup: true,
						onlyAccessSpecifierEdit: false
					})
				}
			}
		}
	}

	processSaveFile = (e, type, notify) => {
		e.preventDefault();
		this.setState({saveType: type})
		if(this.state.fileName === '' || this.state.selectedLocations.length === 0){
			if (this.state.selectedLocations.length === 0 && this.state.fileName !== ''){
				if (type !== 'draft'){
					this.setState({submitted: true})
					console.log("Name and locations should not be empety")
					return
				}else{
					this.setState({submitted: false})
				}

			}else{
				this.setState({submitted: true})
				console.log("Name and locations should not be empety")
				return
			}
		}
		let itemToUpdate = this.state.itemToUpdate
		let updatedName = this.state.fileName.trim()+'.'+itemToUpdate.name?.split('.')[itemToUpdate.name?.split('.').length-1] 
		let data = {
			'id': itemToUpdate.id,
			'name': updatedName,
			'location_tags': this.state.selectedLocations,
			'skill_tags': this.state.selectedSkills,
			'visitors': this.state.selectedVisibility,
			'drafted': type === 'publish' ? false : true,
			'comment': this.state.enabled,
			'notify': notify,
			'all_locations': this.state.selectedRegions?.length === this.state.regions_and_locations?.length,
			'all_skills': this.state.skills?.length === this.state.selectedSkills?.length,
			//selected folder to move
			'destination_folder': this.state.selectedDestinationFolderlist?.value
		}
		let folder = this.state.folderPathNames.join('/')
		// let promiseChain = Promise.resolve();
		// promiseChain = promiseChain.then(this.processSaveFile(e, type, false));
		// if (Boolean(this.state.attachment)) promiseChain = promiseChain.then(this.uploadAttachmentAsync());
		// // return promiseChain;		
		// console.log("====results====", promiseChain);		
		return saveFile(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), itemToUpdate?.id, folder, data)
		.then(result => {
			return result;
		}).catch (error => {
			return error;
		})
	}

  saveAll = async (e, type, bool) => {
    const response = await this.processSaveFile(e, type, bool);
		this.setState({showNotifyAudiencePopup: false})
		let message = "Details saved successfully";
		if(response.status === 200) {
			if(Boolean(this.state.attachment)){
				this.setState({thumbnailUploading: true})
				const fileUploadResponse = await this.uploadAttachmentAsync();
				if(fileUploadResponse.status === 200){
					message = "Details saved and Thumbnail uploaded successfully"
					this.setState({
						alert_message: message,
						alert_type: 'success', 
						show: true,           
						loaded: true,
						thumbnailUploading: false,
						showNotifyAudiencePopup: false
					})			
					this.setState({show:true},()=>{
						window.setTimeout(()=>{
							this.setState({show:false, uploaded: true})
						},3000)
					});	
				}else {
					this.setState({
						alert_message: "Uploading thumbnail failed.Try again",
						alert_type: 'danger', 
						show: true,           
						loaded: true,
						thumbnailUploading: false,
						showNotifyAudiencePopup: false
					})			
					this.setState({show:true},()=>{
						window.setTimeout(()=>{
							this.setState({show:false})
						},3000)
					});					
				}
			}else {
				this.setState({
					alert_message: message,
					alert_type: 'success', 
					show: true,           
					loaded: true,
					thumbnailUploading: false,
					showNotifyAudiencePopup: false
				})			
				this.setState({show:true},()=>{
					window.setTimeout(()=>{
						this.setState({show:false, uploaded: true})
					},3000)
				});					
			}	
		}else{
			this.setState({
				alert_message: response.error,
				alert_type: 'danger', 
				show: true,           
				loaded: true,
				thumbnailUploading: false,
				showNotifyAudiencePopup: false
			})
			this.setState({show:true},()=>{
				window.setTimeout(()=>{
					this.setState({show:false})
				},3000)
			});				
		}
  }	

	countBreadCrumbsNameCharectors = (name) => {
		let shortName = []
		for(let i = 0; i < name?.length; i++){
			if(i < 36){
				shortName.push(name[i])
			}else{
				shortName.push('...')
				break
			}
		}
		return shortName.join('').replace(/%20|%2|%/gi, ' ')
	}

	cancelPagePath = () => {
		let folderPath = location.pathname?.split('/')
		let files = location.pathname?.split('/')
    let file = files.pop()
		let pathName = []
		let path = []
		for(let i = 0; i < folderPath?.length-1; i++){
			pathName.push(folderPath[i])
			if(i < folderPath?.length-2){
				path.push(folderPath[i])
			}
		}
		switch (pathName[pathName?.length-2]) {
			case 'view-resource-detail':
				path.push(file)
				break;
			case 'add-file':
				path
				break;
			default:
				break;
		}
		
		return path.join('/')
	}

	closeNotifyModal = () => {
		this.setState({
			showNotifyAudiencePopup: false,
			onlyAccessSpecifierEdit: false
		})
	}


  getDimensions = async (f) => {
		let meta = {};
		const fileCallbackToPromise = (fileObj) => {
			return Promise.race([
				new Promise((resolve) => {
					if (fileObj instanceof HTMLImageElement) fileObj.onload = resolve;
					else fileObj.onloadedmetadata = resolve;
				}),
				new Promise((_, reject) => {
					setTimeout(reject, 2000);
				})
			]);
		};

		const objectUrl = URL.createObjectURL(f);
		const img = document.createElement('img');
		img.src = objectUrl;
		try {
			await fileCallbackToPromise(img);
			this.setState({ isMetadataPresent: true });
		} catch (error) {
			this.setState({ isMetadataPresent: false });
		}
		return {
			width: img.width,
			height: img.height,
		};
	};

  setThumbnailData = async (e) => {
		this.setState({ ...this.state, [e.target.name]: e.target.files[0] });
		//reset if there is alert
		this.setState({
			show: false,
			alert_message: "",
		})				
		
    e.preventDefault()
    const file = e.target.files[0];
		const {files} = e.target;
    const meta = await this.getDimensions(file);
    if(meta.width < ResourceThumbnailMinWidth || meta.height < ResourceThumbnailMinHeight) {
        this.setState({
          show: true,
					// alert_message: `Upload Failed: Your image needs to be between ${ResourceThumbnailMinWidth}x${ResourceThumbnailMinHeight}px and ${ResourceThumbnailMaxWidth}x${ResourceThumbnailMaxHeight}px - please try again.`,
          alert_message: 'Thumbnail must have a minimum width/height '+ResourceThumbnailMinWidth+"x"+ResourceThumbnailMinHeight+"px",
          alert_type: 'danger',            
          loaded: true,
					attachment: null,
					defaultPreviewImg: null
        }, ()=>{
					window.setTimeout(()=>{
						this.setState({show:false})
					},5000)
				})  
				this.thumbnailRef.current.value = null;
        return true;    
    }
    // if(meta.width > ResourceThumbnailMaxWidth || meta.height > ResourceThumbnailMaxHeight) {
    //   this.setState({
    //     show: true,
		// 		alert_message: `Upload Failed: Your image needs to be between ${ResourceThumbnailMinWidth}x${ResourceThumbnailMinHeight}px and ${ResourceThumbnailMaxWidth}x${ResourceThumbnailMaxHeight}px - please try again.`,
    //     // alert_message: 'Width/Height of Thumbnail must not exceed '+ResourceThumbnailMaxWidth+"x"+ResourceThumbnailMaxHeight+"px",
    //     alert_type: 'danger',            
    //     loaded: true,
		// 		attachment: null
    //   }, ()=>{
		// 		window.setTimeout(()=>{
		// 			this.setState({show:false})
		// 		},5000)
		// 	})   
    //   return true;   
    // }
		this.setPreviewHandler(file);
    const details = {
      name: file.name,
      size: file.size,
      width: meta.width,
      height: meta.height,
    }    
		this.setState({thumbnailMetadata: details})
	}

	setPreviewHandler = (file) => {
		this.setState({defaultPreviewImg: URL.createObjectURL(file)})
	}

	uploadAttachmentAsync = () => {
		const {thumbnailMetadata, attachment, itemToUpdate} = this.state;
		if(Boolean(this.state.attachment)){
			const formData = new FormData();
			formData.append( "thumbnail", attachment)
			formData.append( "content_type", attachment.type)
			formData.append( "name", thumbnailMetadata.name)
			formData.append( "size", thumbnailMetadata.size)
			formData.append( "width", thumbnailMetadata.width)
			formData.append( "height", thumbnailMetadata.height)
			//important
			formData.append( "type", "BusinessResource")
			formData.append( "id", itemToUpdate.id)	
	
			return uploadDefaultThumbnail(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), formData )
			.then(result => {
				return result;
			}).catch (error => {
				return error;
			})
		}
  }  

	deleteThumbnail = (resource_id) => {
		if(resource_id){
			this.setState({deleteThumbnailPopupOpen: false}, () => {
				deleteDefaultThumbnail(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), resource_id, "BusinessResource").then(      
					response => response.json()
				)
				.then(result => {
					if(result.status === 200){
						this.setState({
							alert_message: result.message,
							alert_type: 'success', 
							show: true,
						})
						this.setState({show:true},()=>{
							window.setTimeout(()=>{
								this.setState({show:false, uploaded: true})
							},3000)
						});
					}else{
						this.setState({
							alert_message: result.error,
							alert_type: 'danger', 
							show: true,     
						})
						this.setState({show:true},()=>{
							window.setTimeout(()=>{
								this.setState({show:false, uploaded: true})
							},3000)
						});
					}
				})	
			})			
		}
	}

	deleteThumbnailHandler = () => {
		const { deleteThumbnailPopupOpen } = this.state
		this.setState({deleteThumbnailPopupOpen: !deleteThumbnailPopupOpen})
	}

	moveToFolderSelectHandler = (selected) => {
		if(Boolean(selected)) {
			const { foldersList } = this.state;
			this.setState({selectedDestinationFolderlist: selected || foldersList[0]})
		} else {
			this.setState({selectedDestinationFolderlist: []})
		}
	}

	removeAttachmentHandler = () => {
		this.setState({defaultPreviewImg: null, attachment: null})
	}	

	render(){
		const { enabled, fileName, regions_and_locations, locations, skills, visibilities, show_others, alert_message, alert_type, show, loaded, selectedSkills, selectedLocations, selectedRegions, selectedVisibility, folderPathNames, folderName, path, itemToUpdate, pageState, showDeleteFileModal, deleted, uploaded, submitted, showNotifyAudiencePopup, oldSelectedSkills, oldSelectedLocations, oldSelectedVisibility, oldFileName, onlyAccessSpecifierEdit, thumbnailUploading, attachment, deleteThumbnailPopupOpen, selectedDestinationFolderlist, foldersList, defaultPreviewImg, saveType } = this.state
		const { loadingMessage, business } = this.props
		return(
			<React.Fragment>
				{deleted &&
					<Redirect to={`${this.redirectToPrevFolder()}`} />
				}
				{uploaded && 
					<Redirect push to={`${this.backButtonUrl()}`} />
				}
				{show &&
          <React.Fragment>
            {alert_type === 'success' &&
              <div className="w-3/5 flex items-center bg-green-50 rounded-md px-4 py-3.5 mb-1 fixed top-1 z-50 left-0 right-0 mx-auto">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-green-400 mr-3.5" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                </svg>
                <div className="text-sm text-green-800 font-medium">
                  {alert_message}
                </div>
                <a href="" className="ml-auto">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </a>
              </div>
            }
            {alert_type === 'danger' &&
              <div className="w-3/5 flex items-center bg-red-100 rounded-md px-4 py-3.5 mb-1 fixed top-1 z-50 left-0 right-0 mx-auto">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-red-300 mr-3.5" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
                </svg>
                <div className="text-sm text-red-700 font-medium">
                  {alert_message}
                </div>
                <a href="" className="ml-auto">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-red-300" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </a>
              </div>
            }  
          </React.Fragment>
        }
				{!loaded && 
					<div className="msg-loading flex flex-col items-center w-full justify-center h-screen">
						<p className="mb-1 text-center px-5">{loadingMessage}</p>
						<img className = "w-9" src="/assets/business/loading.gif" />
					</div>
				}
				{loaded &&
					<div className="w-full min-h-screen bg-gray-50">
						<div className="pt-20 md:pt-8 h-full">
							<main className="h-full">
								<div className="max-w-8xl mx-auto px-6 lg:px-8 h-full pb-8">
									<div className="t-breadcrumbs">
										<nav className="flex">
											<ol className="flex items-center flex-wrap">
												<li>
													<div className="flex items-center">
														<Link to="/resource_hub" className="md:text-xl text-sm  font-medium text-gray-500 hover:text-gray-700">Resource hub</Link>
													</div>
												</li>
												{folderPathNames && folderPathNames.map((name, i) =>
													<li key = {i}>
														<div className="flex items-center">
															<svg className="flex-shrink-0 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>
															<Link to = {`/resource_hub/folder/${this.checkPrevUrl(i)}`} className="md:text-xl text-sm  font-medium text-gray-500 hover:text-gray-700">{this.countBreadCrumbsNameCharectors(name)}</Link>
														</div>
													</li>
												)}
												<li>
													<div className="flex items-center">
														<svg className="flex-shrink-0 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>
														<a className="md:text-xl text-sm  font-medium text-gray-700 hover:text-gray-700">{pageState} file(s)</a>
													</div>
												</li>
											</ol>
										</nav>
									</div>
									<Link to={this.backButtonUrl()} className="bg-white text-xs py-2 px-2.5 inline-flex my-4 rounded text-gray-700 border-solid border-2 border-gray-200">Back</Link>
									{!loaded && 
										<div className="msg-loading flex flex-col items-center w-full justify-center h-screen">
											<p className="mb-1 text-center px-5">{loadingMessage}</p>
											<img className = "w-9" src="/assets/business/loading.gif" />
										</div>
									}
									{loaded &&
										<div>
											<div className="bg-white rounded-md shadow px-6">
													<div className="border-0">
															<div className="text-lg flex items-center views-dd text-left border-0 w-full font-medium py-6 cursor-default">
																	File
																	{/* <div className="ml-auto">
																		<svg xmlns="http://www.w3.org/2000/svg" className="text-gray-900 h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
																			<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
																		</svg>
																	</div> */}
															</div>
															<div>
																	<div className="p-0 mt-5 relative">
																		<>
																		<div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start border-t border-gray-200 py-5'>
																			<label htmlFor="first-name" className="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">
																				File infos
																			</label>
																			<div className="mt-1 sm:mt-0 sm:col-span-2">
																				<div className="relative flex items-center jusfity-center rounded-sm min-w-60 max-w-60 w-60 h-44 overflow-hidden">
																					{['image', 'video', 'audio'].includes(itemToUpdate.file_type?.split('/')[0]) &&
																						<div className="w-full h-full flex items-center justify-center">	
																							<img src={itemToUpdate.thumbnail ? `${itemToUpdate.thumbnail}` : (itemToUpdate.file_type?.split('/')[0] === 'audio' ? "/assets/img_audio.png"  : "/assets/img_default_files.png")} className="ml-auto mr-auto max-h-full w-full h-full object-cover block rounded-sm"/>
																						</div>
																					}
																					{['document'].includes(itemToUpdate.file_type?.split('/')[0]) &&
																						<div className="flex items-center justify-center w-full h-full">	
																							<img src= {this.showDocumentImage(itemToUpdate.file_type)} className=" ml-auto mr-auto w-16 object-cover block rounded-sm"/>
																						</div>
																					}
																					{!['image', 'document', 'video', 'audio'].includes(itemToUpdate.file_type?.split('/')[0]) &&
																						<div className="bg-gray-300">
																							<div>
																								<img src={"/assets/img_default_files.png"} className="w-full h-full object-cover block rounded-md p-6"/>
																							</div>
																						</div>
																					}
																					<div className="absolute top-2 right-2 p-1.5 rounded bg-gray-900 flex items-center justify-center">
																						{this.chooseIcon(itemToUpdate.file_type)}
																						{/* <svg xmlns="http://www.w3.org/2000/svg" className="text-white h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
																							<path fillRule="evenodd" d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z" clipRule="evenodd" />
																						</svg> */}
																							{/* <div className="text-gray-400 text-xs ml-1.5">{this.chooseIconName(itemToUpdate.file_type)}</div> */}
																					</div>
																				</div>	
																			</div>
																		</div>
																		<div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start border-t border-gray-200 py-5'>
																			<label className="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">Select Thumbnail</label>
																			<div className='mt-1 sm:mt-0 sm:col-span-2'>
																				<div className='relative'>
																					{(() => {
																						if (itemToUpdate?.default_thumbnail && (!Boolean(defaultPreviewImg))) {
																							return (
																								<img className="mb-2 w-32" src={itemToUpdate?.default_thumbnail} alt="thumbnail" />
																							)
																						} else if (Boolean(defaultPreviewImg)) {
																							return (
																								<div className='border border-2 border-gray-400 shadow w-48 pl-3 rounded relative'>
																									<p className='mb-2 text-gray-400'>Preview</p>
																									<img className='mb-2 w-32' src={defaultPreviewImg} />
																									{!thumbnailUploading && (
																										<div className='absolute -top-2 right-0 p-2 text-gray-400 cursor-pointer' title="Remove attachment" 
																											onClick={(e) => this.removeAttachmentHandler()}
																										>x</div>
																									)}	
																								</div>
																							)
																						} else {
																							return (
																								<></>
																							)
																						}																					
																					})()}
																					{thumbnailUploading && (
																						<div className="inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm text-white bg-white" disabled="">
																							<svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-gray-800" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
																								<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
																								<path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
																							</svg>
																							<span className='text-gray-800'>
																								uploading...
																							</span>
																						</div>
																					)}
																					{itemToUpdate?.default_thumbnail && !Boolean(defaultPreviewImg) && (
																						<div className='absolute right-0 cursor-pointer' title="Delete Thumbnail">
																							<TrashIcon className="h-6 w-6 text-red-600" aria-hidden="true" onClick={(e) => this.deleteThumbnailHandler()} />
																						</div>
																					)}																					
																				</div>
																				<div>
																					<input ref={this.thumbnailRef} type="file" onChange={(e) => this.setThumbnailData(e)} name="attachment" accept="image/*"/>
																				</div>
																			</div>
																		</div>
																		
																		<div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start border-t border-gray-200 py-5'>
																			<label htmlFor="first-name" className="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">
																				File name*
																			</label>
																			<div className="mt-1 sm:mt-0 sm:col-span-2">
																				<input type="text" className="lg:w-80 border rounded-md px-2 py-1.5 w-full shadow-sm" value = {fileName} onChange={(e) => this.handleFileNameChange(e)}/>
																			</div>
																		</div>
																		{(submitted && fileName === '') &&
																			<div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start'>
																				<div className="mt-1 sm:mt-0 sm:col-span-1"></div>
																				<div className="sm:col-span-2 -mt-3.5 mb-2">
																					{/* {(submitted && fileName === '') && */}
																						<span className="text-red-500 text-sm">*File name is required</span>
																					
																				</div>
																			</div>
																		}
																		{itemToUpdate?.inside_folder && (
																			<div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start border-t border-gray-200 py-5'>
																				<label className="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">Move to Folder</label>
																				<div className='mt-1 sm:mt-0 sm:col-span-2 w-full lg:w-80'>
																					<Select
																						isMulti={false}
																						options={foldersList}
																						onChange={(val) => this.moveToFolderSelectHandler(val)}
																						placeholder="Select Folder"
																						isClearable={selectedDestinationFolderlist?.value}
																						defaultValue={selectedDestinationFolderlist}
																					/>	
																				</div>
																			</div>	
																		)}
																		<div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start border-t border-gray-200 py-5'>
																			<label htmlFor="first-name" className="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">
																				Skill(s) tag
																			</label>
																			<div className="mt-1 sm:mt-0 sm:col-span-2">
																				{/* <input type="text" className="max-w-xs border rounded-md px-2 py-1.5 w-full shadow-sm" /> */}
																				<div className="relative filters_box w-full lg:w-80">
																					<Menu as="div" className="relative block text-left">
																						<div>
																							<Menu.Button className="flex justify-center w-full rounded border border-gray-300 shadow-sm pl-4 pr-2 py-2 bg-white text-sm text-gray-400 focus:outline-nonefocus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500 instructor_items_button">

																								{selectedSkills?.length === skills?.length && <p className = "text-gray-900">All Skills Selected</p>
																								}
																								{(selectedSkills?.length < skills?.length && selectedSkills?.length > 0 ) && 
																									// <React.Fragment>
																									// 	{(selectedSkills?.length <= 2 && selectedSkills?.length !== 0) && 
																									// 		<p className = "text-gray-900">{this.checkSelectedSkillsName()}</p>
																									// 	}
																									// 	{(selectedSkills?.length > 2 && selectedSkills?.length < skills?.length) && 
																									// 		<p className = "text-gray-900">{this.checkSelectedSkillsName()} +{selectedSkills?.length-2}</p>
																									// 	}
																									// </React.Fragment>
																									
																									<React.Fragment>
																										{selectedSkills?.length === 1 ? 
																											<p className = "text-gray-900">{selectedSkills?.length} skill selected </p>
																										:
																											<p className = "text-gray-900">{selectedSkills?.length} skill(s) selected </p>
																										}
																									</React.Fragment>
																								}
																								{(selectedSkills?.length === 0  && selectedSkills?.length !== skills?.length) && <p>Select Skill</p>}

																								<ChevronDownIcon className="ml-auto text-gray-900 h-5 w-5" aria-hidden="true" />
																							</Menu.Button>
																						</div>

																						<Transition
																							as={Fragment}
																							show={true}
																							enter="transition ease-out duration-100"
																							enterFrom="transform opacity-0 scale-95"
																							enterTo="transform opacity-100 scale-100"
																							leave="transition ease-in duration-75"
																							leaveFrom="transform opacity-100 scale-100"
																							leaveTo="transform opacity-0 scale-95">
																							<Menu.Items className="origin-top-right z-10 absolute right-0 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none instructor_items hidden filter_items">
																								<div className="flex items-center p-2 border-b">
																									<div className="mr-auto">
																										<label className="inline-flex items-center text-gray-400 text-sm mb-2 gap-1">
																											<input type="checkbox" className="w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300" checked = {this.selectAllSkills()} onChange = {(e) => this.handleSelectAllSkills(e)}/>
																											Select all/Clear all	
																										</label>
																									</div>
																								</div>
																								<div className="p-2 max-h-60 overflow-y-auto">
																									<div className="py-1">
																										<div>
																											{skills.map((skill, i) => 
																												<Menu.Item key = {i}>
																													{({ active }) => (
																														<label className="flex items-center text-gray-900 text-sm mb-2">
																															<input type="checkbox" className="w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300 mr-1" checked = {selectedSkills.includes(skill?.id)} value={skill.id} onChange = {(e) => this.handleSelectSkills(e)}/>
																																{skill.name}
																														</label>
																															
																													)}
																												</Menu.Item>
																											)}
																										</div>
																									</div>
																								</div>
																							</Menu.Items>
																						</Transition>
																					</Menu>
																				</div>
																				{selectedSkills?.length > 0 &&
																					<div className="flex gap-2.5 mt-2 flex-wrap">
																						{selectedSkills?.map((skillId, j) =>
																							<div className="bg-purple-100 text-purple-800 flex items-center text-xs gap-1.5 px-2.5 py-1 rounded-xl font-medium " key={j}>{this.checkSelectedSkillsName(skillId)} <a className='cursor-pointer' onClick = {(e) => this.removeSelectedSkill(skillId)}>
																									<svg xmlns="http://www.w3.org/2000/svg" className="text-violet-800 h-3.5 w-3.5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
																										<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
																									</svg>
																								
																								</a></div>
																						)}
																					</div>
																				}
																			</div>
																		</div>
																		<div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start border-t border-gray-200 py-5'>
																			<label htmlFor="first-name" className="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">
																				Location(s) tag*
																			</label>
																			<div className="mt-1 sm:mt-0 sm:col-span-2">
																				<div className="relative filters_box  w-full lg:w-80">
																					<Menu as="div" className="relative block text-left">
																						<div>
																							<Menu.Button className="inline-flex justify-center w-full rounded border border-gray-300 shadow-sm pl-4 pr-2 py-2 bg-white text-sm text-gray-400 focus:outline-none focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500 location_items_button">
																								{selectedLocations?.length === locations?.length && <p className = "text-gray-900">All Locations Selected</p>
																								}
																								{(selectedLocations?.length < locations?.length && selectedLocations?.length > 0) && 
																									<React.Fragment>
																										{/* {(selectedLocations?.length === 1 && selectedLocations?.length !== 0) && 
																											<p className = "text-gray-900">{this.locationName()}</p>
																										}
																										{(selectedLocations?.length > 1 && selectedLocations?.length < locations?.length) && 
																											<div className = "flex text-gray-900">{this.locationName()} {(selectedLocations?.length - this.locationName().split(',')?.length) > 0 && <p>+{selectedLocations?.length - this.locationName().split(',')?.length}</p>}</div>
																										} */}
																										{selectedLocations?.length === 1 ?
																											<p className = "text-gray-900">{selectedLocations?.length} location selected</p>
																										:
																											<p className = "text-gray-900">{selectedLocations?.length} location(s) selected</p>
																										}
																									</React.Fragment>
																								}
																								{(selectedLocations?.length === 0  && selectedLocations?.length !== locations?.length) && <p>Select Location</p>} 
																								<ChevronDownIcon className="ml-auto h-5 w-5 text-gray-900" aria-hidden="true" />
																							</Menu.Button>
																						</div>

																						<Transition
																							as={Fragment}
																							show={true}
																							enter="transition ease-out duration-100"
																							enterFrom="transform opacity-0 scale-95"
																							enterTo="transform opacity-100 scale-100"
																							leave="transition ease-in duration-75"
																							leaveFrom="transform opacity-100 scale-100"
																							leaveTo="transform opacity-0 scale-95">
																							<Menu.Items className="origin-top-right z-10 absolute right-0 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none hidden location_items filter_items">
																								<div className="flex items-center p-2 border-b">
																									<div className=" mr-auto">
																										<label className="inline-flex items-center text-gray-400 text-sm mb-2 gap-1">
																											<input type="checkbox" className="w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300" checked = {this.selectedAllRegLoc()} onChange = {(e) => this.selectedAllRegionsLocations(e)}/>
																											Select all/Clear all
																										</label>
																									</div>
																								</div>
																								<div className="p-2 max-h-60 overflow-y-auto">
																									{regions_and_locations.map((region, i) =>
																										<div className="py-1" key = {i}>
																											{show_others &&
																												<Menu.Item>
																													{({ active }) => (
																														<label className="flex items-center text-gray-900 text-sm mb-2">
																															<input type="checkbox" className="w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300 mr-1" onChange={(e) => this.selectRegions(e, region)} value={region?.region?.name} checked={selectedRegions.includes(region.region.name)}/>
																															{region.region.name}
																														</label>
																															
																													)}
																												</Menu.Item>
																											}
																											<div className="ml-5">
																												{region.locations.map((location, j) =>
																												<Menu.Item key = {j}>
																													{({ active }) => (
																														<label className="flex items-center text-gray-900 text-sm mb-2">
																															<input type="checkbox" className="w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300 mr-1" checked={selectedLocations.includes(location?.id)} value={location.id} onChange={(e) => this.selectLocation(e, region)}/>
																															{location.name}
																														</label>
																															
																													)}
																												</Menu.Item>
																												)}
																											</div>
																										</div>
																									)}
																								</div>
																							</Menu.Items>
																						</Transition>
																					</Menu>
																				</div>
																				{selectedLocations?.length > 0 &&
																					<div className="flex gap-2.5 mt-2 flex-wrap">
																						{selectedLocations.map((locId, j) =>
																							<div className="red-tag flex items-center text-xs gap-1.5 px-2.5 py-1 rounded-xl font-medium" key={j}>{this.locationName(locId)} <a className='cursor-pointer' onClick = {(e) => this.removeSelectedLocation(locId)}>
																								<svg xmlns="http://www.w3.org/2000/svg" className="text-red-400 h-3.5 w-3.5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
																									<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
																								</svg>
																								
																								</a></div>
																						)}
																					</div>
																				}
																			</div>
																		</div>
																		{(submitted && selectedLocations?.length === 0 && saveType === 'publish') &&
																			<div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start'>
																				<div className="mt-1 sm:mt-0 sm:col-span-1"></div>
																				<div className="sm:col-span-2 -mt-3.5 mb-2">
																					<span className="text-red-500 text-sm">*Location(s) tag is required</span>
																				</div>
																			</div>
																		}
																		<div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start border-t border-gray-200 py-5'>
																			<label htmlFor="first-name" className="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">
																				Visibility - Which roles have access to view?
																			</label>
																			<div className="mt-1 sm:mt-0 sm:col-span-2">
																				{/* <input type="text" className="max-w-xs border rounded-md px-2 py-1.5 w-full shadow-sm" /> */}
																				<div className="relative filters_box w-full lg:w-80">
																					<Menu as="div" className="relative block text-left">
																						<div>
																							<Menu.Button className="flex justify-center w-full rounded border border-gray-300 shadow-sm pl-4 pr-2 py-2 bg-white text-sm text-gray-400 focus:outline-nonefocus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500 class_items_button">
																								{selectedVisibility && <p className = "text-gray-900">{selectedVisibility}</p>}
																								{!selectedVisibility && <p>Select Visibility</p>}
																								<ChevronDownIcon className="ml-auto text-gray-900 h-5 w-5" aria-hidden="true" />
																							</Menu.Button>
																						</div>

																						<Transition
																							as={Fragment}
																							show={true}
																							enter="transition ease-out duration-100"
																							enterFrom="transform opacity-0 scale-95"
																							enterTo="transform opacity-100 scale-100"
																							leave="transition ease-in duration-75"
																							leaveFrom="transform opacity-100 scale-100"
																							leaveTo="transform opacity-0 scale-95">
																							<Menu.Items className="origin-top-right z-10 absolute right-0 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none class_items hidden filter_items">
																								<div className="p-2 max-h-60 overflow-y-auto">
																									<div className="py-1">
																										<div>
																											{visibilities.map((visibility, i) => 
																												<Menu.Item key = {i}>
																													{({ active }) => (
																														<label className="flex items-center text-gray-900 text-sm mb-2">
																															<input type="checkbox" className="w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300 mr-1" checked = {selectedVisibility === visibility} value={visibility} onChange = {(e) => this.handleSelectVisibility(e)}/>
																																{visibility}
																														</label>
																															
																													)}
																												</Menu.Item>
																											)}
																										</div>
																									</div>
																								</div>
																							</Menu.Items>
																						</Transition>
																					</Menu>
																				</div>
																			</div>
																		</div>
																		{/* <div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:py-5'>
																			<label htmlFor="first-name" className="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">
																				Contributors - Which roles have access to modify?
																			</label>
																			<div className="mt-1 sm:mt-0 sm:col-span-2">
																				<input type="text" className="max-w-xs border rounded-md px-2 py-1.5 w-full shadow-sm" />
																			</div>
																		</div> */}
																		{/* <div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:py-5'>
																			<label htmlFor="first-name" className="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">
																				Allow comments
																			</label>
																			<div className="mt-1 sm:mt-0 sm:col-span-2 flex">
																				<Switch
																					checked={enabled}
																					onChange={(e) => this.handleSwitch()}
																					className={classNames(
																						enabled ? 'bg-indigo-600' : 'bg-gray-200',
																						'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none'
																					)}
																				>
																					<span className="sr-only">Use setting</span>
																					<span
																						aria-hidden="true"
																						className={classNames(
																							enabled ? 'translate-x-5' : 'translate-x-0',
																							'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
																						)}
																					/>
																				</Switch>
																				{enabled ?
																					<div className="block text-sm font-medium text-gray-500 sm:mt-px pl-2">
																						Yes
																					</div>
																					:
																					<div className="block text-sm font-medium text-gray-500 sm:mt-px pl-2">
																						No
																					</div>
																				}
																			</div>
																		</div> */}
																		<div className='flex flex-wrap pb-6 items-center justify-end gap-3'>
																			<button className='flex items-center px-4 py-3 border border-gray-300 shadow-sm font-medium rounded-md gap-1' onClick={() => this.setState({showDeleteFileModal: true})}>
																				<svg xmlns="http://www.w3.org/2000/svg" className="text-gray-900 h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
																					<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
																				</svg>
																				<div className='text-gray-900 text-base'>Delete file</div>
																			</button>
																			{itemToUpdate?.drafted &&
																				<button className='flex items-center px-4 py-3 gap-1 border border-gray-300 shadow-sm font-medium rounded-md' onClick={(e) => this.saveAll(e, 'draft', false)}>
																					<svg xmlns="http://www.w3.org/2000/svg" className="text-gray-900 h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
																						<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 7H5a2 2 0 00-2 2v9a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-3m-1 4l-3 3m0 0l-3-3m3 3V4" />
																					</svg>
																					<div className='text-gray-900 text-base'>Save as draft</div>
																				</button>
																			}
																		</div>
																		</>
																	</div>
															</div>
													</div>
											</div>
											<div className='flex mt-4 gap-3 md:justify-end'>
												<Link to = {this.cancelPagePath()} className="bg-white text-base rounded-md text-gray-700 font-medium border border-gray-200 px-6 py-3" href="">Cancel</Link>
												<button className="flex text-base font-medium rounded-md text-white bg-dark-blue px-6 py-3 gap-2" onClick={(e) => this.handlePublishFile(e, 'publish')}>
														{(() => {
														if (thumbnailUploading) { 
															return (<span className='ml-1'><img className = "w-6 h-6" src="/assets/business/white-loader.gif" /></span>)
														}else {
															return (
															<svg xmlns="http://www.w3.org/2000/svg" className="text-white h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
																<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
															</svg>
																)
														}
													})()}		
													Publish											
												</button>
											</div>
										</div>
									}
								</div>
							</main>
						</div>
					</div>
				}
				<Transition.Root show={showDeleteFileModal} as={Fragment}>
					<Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={(e) => this.closeDeleteModal(e)}>
						<div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0"
								enterTo="opacity-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100"
								leaveTo="opacity-0"
							>
								<Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
							</Transition.Child>
		
							{/* This element is to trick the browser into centering the modal contents. */}
							<span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
								&#8203;
							</span>
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
								enterTo="opacity-100 translate-y-0 sm:scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 translate-y-0 sm:scale-100"
								leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							>
								<div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
									<div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
										<button
											type="button"
											className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
											onClick={(e) => this.closeDeleteModal(e)}
										>
											<span className="sr-only">Close</span>
											<XIcon className="h-6 w-6" aria-hidden="true" />
										</button>
									</div>
									<div className="sm:flex sm:items-start">
										<div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
											<ExclamationIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
										</div>
										<div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
											<Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
												Delete File
											</Dialog.Title>
											<div className="mt-2">
												<p className="text-sm text-gray-500">
													Are you sure you want to delete file : {fileName} ?
												</p>
											</div>
										</div>
									</div>
									<div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
										<button
											type="button"
											className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
											onClick={(e) => this.processDeleteFile(e)}
										>
											Delete
										</button>
										<button
											type="button"
											className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
											onClick={(e) => this.closeDeleteModal(e)}
										>
											Cancel
										</button>
									</div>
								</div>
							</Transition.Child>
						</div>
					</Dialog>
				</Transition.Root>
				<Transition.Root show={showNotifyAudiencePopup} as={Fragment}>
					<Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={(e) => this.closeNotifyModal(e)}>
						<div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0"
								enterTo="opacity-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100"
								leaveTo="opacity-0"
							>
								<Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
							</Transition.Child>
		
							{/* This element is to trick the browser into centering the modal contents. */}
							<span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
								&#8203;
							</span>
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
								enterTo="opacity-100 translate-y-0 sm:scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 translate-y-0 sm:scale-100"
								leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							>
								<div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
									<div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
										<button
											type="button"
											className="bg-white rounded-md text-gray-400 hover:text-gray-500"
											onClick={(e) => this.closeNotifyModal(e)}
										>
											<span className="sr-only">Close</span>
											<XIcon className="h-6 w-6" aria-hidden="true" />
										</button>
									</div>
									<div className="sm:flex sm:items-start">
										{/* <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
											<ExclamationIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
										</div> */}
										<div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
											<Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                        Notify audience
											</Dialog.Title>
											<div className="mt-3">
												<p className="text-sm text-gray-700">
												Do you want to send a notification to the {!itemToUpdate?.drafted && onlyAccessSpecifierEdit ? 'new audience members' : 'audience'} that you've {itemToUpdate?.drafted ? 'published' : 'updated'} this file?
												</p>
											</div>
										</div>
									</div>
									<div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse gap-3">
                    <button
                      className={`bg-dark-blue flex items-center px-4 py-2 gap-1 border border-gray-300 shadow-sm font-medium rounded-md hover:bg-gray-800`}
                      onClick={(e) => this.saveAll(e, 'publish', true)}
                    >
                      <div className="flex items-center gap-1">
                        <svg xmlns="http://www.w3.org/2000/svg" className=" text-white h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                          <path d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z" />
                        </svg>
                        <div className='text-white text-base'>Send</div>
                      </div>
                    </button>
										<button
											type="button"
											className="w-full inline-flex items-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-200 sm:mt-0 sm:w-auto sm:text-sm"
											onClick={(e) => this.saveAll(e, 'publish', false)}
										>
											Don't Send
										</button>
									</div>
								</div>
							</Transition.Child>
						</div>
					</Dialog>
				</Transition.Root>
				{/* confirm pop to delete thumbnail */}
				<Transition.Root show={deleteThumbnailPopupOpen} as={Fragment}>
					<Dialog as="div" className="relative z-10" onClose={() => this.deleteThumbnailHandler()}>
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0"
							enterTo="opacity-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
						>
							<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
						</Transition.Child>

						<div className="fixed inset-0 z-10 overflow-y-auto">
							<div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
								<Transition.Child
									as={Fragment}
									enter="ease-out duration-300"
									enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
									enterTo="opacity-100 translate-y-0 sm:scale-100"
									leave="ease-in duration-200"
									leaveFrom="opacity-100 translate-y-0 sm:scale-100"
									leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
								>
									<Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
										<div className="sm:flex sm:items-start">
											<div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
												<ExclamationIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
											</div>
											<div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
												<Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
													Delete Thumbnail?
												</Dialog.Title>
											</div>
										</div>
										<div className="mt-5 sm:mt-4 sm:ml-10 sm:flex sm:pl-4">
											<button
												type="button"
												className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:w-auto sm:text-sm"
												onClick={() => this.deleteThumbnail(itemToUpdate.id)}
											>
												Delete
											</button>
											<button
												type="button"
												className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
												onClick={() => this.deleteThumbnailHandler()}
											>
												Cancel
											</button>
										</div>
									</Dialog.Panel>
								</Transition.Child>
							</div>
						</div>
					</Dialog>
    		</Transition.Root>				
			</React.Fragment>
		)
	}
}