import React from 'react';

export default function SubRequestLocationsShow(props){
  const { subRequestLocations, setEditSubRequestLocations, user } = props
  const locations = subRequestLocations?.locations || {}

  const handleEditSubRequestLocations = (data) => {
    setEditSubRequestLocations(data)
  }

  return(
    <React.Fragment>
      {Object.keys(subRequestLocations)?.length > 0 &&
        <React.Fragment>
          {((user?.clubready_feature || user?.fisikal_feature) && subRequestLocations?.locations?.length == 0) ?
            null
            :
            <div className='bg-white flex flex-col rounded-md shadow px-6 py-7'>
              <div className="flex items-center mb-3 pb-5 border-b border-gray-200">
                <div className="text-lg font-medium flex-auto">Where would you like to receive sub requests?</div>
                {(!user?.clubready_feature && !user?.fisikal_feature) &&
                  <button className="flex bg-dark-blue text-sm text-white py-2 px-4 rounded-md h-10 items-center" onClick={() => handleEditSubRequestLocations(true)}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-1.5" viewBox="0 0 20 20" fill="currentColor">
                      <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                    </svg>
                    Edit
                  </button>
                }
              </div>
              {Object.keys(subRequestLocations)?.length > 0 &&
                (locations.map((region, i) =>
                  <div key={i} className={`sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start py-3`}>
                    <label className="block text-sm font-normal text-gray-500">
                      {region?.region}
                    </label>
                    <div className='mt-1 sm:mt-0 sm:col-span-2 flex'>
                      <div className='text-sm font-normal text-gray-900'>
                        {region?.locations}
                      </div>
                    </div>
                  </div>
                ))
              }
            </div>
          }
        </React.Fragment>
      }
    </React.Fragment>
  )
}