import React, { useState, useEffect } from 'react'
import { Channel, useChatContext, useChannelActionContext } from 'stream-chat-react'
import ChannelBody from './channelBody'
import styled from 'styled-components'
import { sendGroupMessage } from './../helpers'
import Cookies from 'js-cookie';
import { getSudomain } from './../../../helpers'
import ChannelEmptyState from './channelEmptyState'
import CustomMessageInput from './customMessageInput/customMessageInput' 
import CustomMessage from './customMessage/customMessage'
import CustomThreadHeader from './customThread/customThreadHeader'
import { DateSeparator } from './customMessage/customDateSeparator/customDateSeparator'
import CustomSystemMessage from './customSystemMessage/customSystemMessage'

const Container = styled.div`
    height: 100%;
  `;

export default function CustomChannel({selChannel, user, permissions}) {
  const { client, channel :  activeChannel, setActiveChannel } = useChatContext()
  const [ channel, setChannel ] = useState(selChannel)
  const dataFormat = user?.date_format_web

  const sendMessageDataForNotification = (param) => {
    if(activeChannel){
      if(param.channel.id == activeChannel.id && param.channel.disabled == true){
        window.location.reload()
      }
    }else{
      if(param.channel.id == selChannel.id && param.channel.disabled == true){
        window.location.reload()
      }
    }
  }

  const letWatchChannel = async (chnl) => {
    await chnl.watch()
  }
  
  useEffect(() => {
    if(activeChannel){
      setChannel(activeChannel)
      letWatchChannel(activeChannel)
    }else{
      setActiveChannel(selChannel)
      letWatchChannel(selChannel)
    }
  })

  useEffect(() => {
    channel.on('channel.updated', sendMessageDataForNotification);

    return () => {
      channel.off('channel.updated', sendMessageDataForNotification);
    };
  }, [channel]);

  if(!channel) <div>Loading ...</div>

  return(
    <Container>
      <Channel channel={channel} Input={CustomMessageInput} Message={(previewProps) => (<CustomMessage {...previewProps} dataFormat = {dataFormat} />)} ThreadHeader={CustomThreadHeader} EmptyStateIndicator={ChannelEmptyState} DateSeparator={(previewProps) => (<DateSeparator {...previewProps} dataFormat = {dataFormat}/>)} MessageSystem={(previewProps) => (<CustomSystemMessage {...previewProps} dataFormat = {dataFormat}/>)}>
        <ChannelBody user={user} permissions={permissions}/>
      </Channel>
    </Container>
  )
}