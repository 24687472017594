import React, { Fragment, useState } from "react";
import CustomPopupContainerComponent from './../../../../../../subRequestDetails/components/customPopupContainerComponent';
import { axiosRequest } from './../../../../../../subRequests/helpers/helpers';

export default function ReopenConfirmationComponent(props) {
	const { user, showReopenConfirmationPopup, closeReopenConfirmationPopup, handleConfirmReopen, reopenEventId } = props
	const [ disableConfirmBtn, setDisableConfirmBtn ] = useState(false)

	const handleConfirm = () => {
		setDisableConfirmBtn(true)
		handleConfirmReopen(reopenEventId)
	}

  return (
		<Fragment>
			<CustomPopupContainerComponent show={showReopenConfirmationPopup} close={closeReopenConfirmationPopup} title={'Reopen Sub Request'} customWidth={''}>
				<div className="flex flex-col w-full border-t border-gray-300 pl-10 pr-6 pt-3 mt-2 gap-6">
					<div className="text-sm font-medium text-neutral-500">
						{user.show_responsibility_text ? 
							<span>
								<ol className="list-decimal">
									<li>All instructors who accepted previously will be moved to 'No Response' state. We want to ensure they are still free to sub.</li>
									<li>Instructors who did not respond initially will be notified again.</li>
									<li>Instructors who declined will not receive another notification. </li>
								</ol>
							</span>
						:
							<span>Are you sure you want to reopen this sub request? By confirming, you will now be the requesting instructor. After you confirm, make sure you invite instructors to sub.</span>
						}
					</div>
					<div className="flex justify-end">
						<button 
							className={`bg-gray-900 rounded-xl text-white flex items-center justify-center px-5 py-2 text-sm ${(disableConfirmBtn) ? 'opacity-50 cursor-not-allowed' : ''}`}
							onClick={() => handleConfirm()}
							disabled={disableConfirmBtn}
						>
							Continue
						</button>
					</div>
				</div>
			</CustomPopupContainerComponent>
		</Fragment>
	)
}