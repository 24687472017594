import React, { Fragment, useState, useEffect } from "react";
import LocationDropDownComponent from './locationDropDownComponent';
import { getTheClassesHash } from '../../../../helpers';

export default function LocationSelectorComponent(props) {
  const { i, newClass, instructorLocations, handleSaveLocation } = props
  const [ locationOptions, setLocationOptions ] = useState(getTheClassesHash(instructorLocations) || [])

  useEffect(() => {
    setLocationOptions(getTheClassesHash(instructorLocations))
  }, [instructorLocations])

  return(
    <Fragment>
      <div className="flex flex-col gap-2 w-full">
        <div className={`flex ${i > 0 ? 'lg:hidden' : ''} text-base md:text-lg text-black font-semibold basic-business-location`}>
            Location
          </div>
        <div>
          <LocationDropDownComponent
            i={i}
            instructorLocations={instructorLocations}
            selectedLocationId={newClass?.selectedLocation?.id}
            selectedLocationName={newClass?.selectedLocation?.name}
            handleSaveLocation={(locationId, locationName) => handleSaveLocation(locationId, locationName, i)}
            locationOptions={locationOptions}
            loading={false}
          />
        </div>
      </div>
    </Fragment>
  )
}