import React, { Fragment, useState } from "react";
import { Accordion, Card } from "react-bootstrap";
import { AngleIcon, ExclamationIcon, TashIcon } from './../../../../assets'
import { axiosRequest } from './../../../../helpers/helpers'
import ReactTooltip from "react-tooltip";
import InstructorDetailComponent from './../../cardViewComponents/cardComponent/instructorDetailComponent';
import ResendInviteAllButtonComponent from './../../../../../subRequestDetails/components/usersListSectionComponent/resendInviteAllButtonComponent';
import ResendInviteButtonComponent from './../../../../../subRequestDetails/components/usersListSectionComponent/resendInviteButtonComponent';

export default function UsersListAccordionComponent(props) {
  const { eventData, eventId, handleAlert, handleCloseAndReloadData, user, handleUpdateEvent, calendarEvent, handleUpdateCalendarEvent } = props
  const [ disableApproveBtn, setDisableApproveBtn ] = useState(false)
  const [ showInstructorDetail, setShowInstructorDetail ] = useState(false)
  const [ userToShowDetails, setUserToShowDetails ] = useState({})
  const [ openAccordionKey, setOpenAccordionKey ] = useState([])
  const [ accordionOpen, setAccordionOpen ] = useState(false)

  const handleShowInstructorDetails = (user) => {
    setUserToShowDetails(user)
    setShowInstructorDetail(true)
  }

  const handleCloseInstructorDetails = () => {
    setShowInstructorDetail(false)
    setUserToShowDetails({})
  }

  const processRemoveUserFromEvent = (userId) => {
    setDisableApproveBtn(true)
    let params = {
      inst_id: userId,
      preview: true
    }
    let url = `/api/v3/business_events/${eventData?.id}/delete_invite`;
    axiosRequest(url, 'PATCH', params, 'data').then((result) => {
      if(result?.status == 200){
        handleAlert(true, result?.success, 'success');
        handleUpdateEvent(result?.event);
      }else{
        handleAlert(true, result?.error, 'danger');
      }
      setDisableApproveBtn(false)
      setTimeout(() => {
        handleAlert(false, '', ''); 
      }, 3000)
    })
  }

  const handleApprove = (acceptedUserId) => {
    setDisableApproveBtn(true)
    let url = `/api/v2/sub_requests/update_status`
    let params = {
      status: 'confirm',
      event_id: eventId,
      inst_id: acceptedUserId,
      preview: true,
      gql_event_details: true,
      calendar_event: calendarEvent
    }
    axiosRequest(url, 'PATCH', params, 'data').then((res) => {
      if(Object.keys(res).includes('status') && res?.status === 200){
        handleAlert(true, 'Status Updated', 'success');
        handleUpdateEvent(res?.event);
        if(calendarEvent) handleUpdateCalendarEvent(res?.calendar_event, 'updated');
      }else{
        handleAlert(true, res?.error, 'danger');
      }
      setDisableApproveBtn(false)
      setTimeout(() => {
        handleAlert(false, '', ''); 
        // handleCloseAndReloadData();
      }, 3000)
    })
  }

  const handleAccordionClick= (accordKey) => {
    if(openAccordionKey.includes(accordKey)){
      setOpenAccordionKey(openAccordionKey.filter(item => item !== accordKey))
    }else{
      setOpenAccordionKey([...openAccordionKey, accordKey])
    }
    setAccordionOpen(!accordionOpen)
  }

  return (
    <Fragment>
      {showInstructorDetail &&
        <InstructorDetailComponent showInstructorDetail={showInstructorDetail} detailInstructor={userToShowDetails} close={handleCloseInstructorDetails} handleAlert={handleAlert}/>
      }
      <Accordion className="bg-white rounded">
        <Card className="border-0 relative">
          <Accordion.Toggle eventKey="0" className="bg-custom-gray rounded flex items-center gap-2 py-2 px-3 mt-2 w-full" disabled={(['Open', 'Re-Opened'].includes(eventData?.status_content) && eventData?.pending_subs?.length == 0) || (eventData?.show_manger_request_buttons && eventData?.invited_instructors?.length == 0) || ((['Pending Approval', 'Pending Your Approval', 'Pending Manager Approval'].includes(eventData?.status_content) || eventData?.status_content.toLowerCase().includes('pending')) && eventData?.accepted_users.length == 0)} onClick={() => handleAccordionClick('0')}>
            <div className={`${(['Open', 'Re-Opened'].includes(eventData?.status_content) || eventData?.show_manger_request_buttons) ? 'bg-yellow-500' : 'bg-pending-event'} w-2 h-4 rounded-sm`}></div>
            <div className='text-xs font-semibold'>{(['Open', 'Re-Opened'].includes(eventData?.status_content) || eventData?.show_manger_request_buttons) ? `No Responses (${(['Open', 'Re-Opened'].includes(eventData?.status_content)) ? eventData?.pending_subs?.length : eventData?.invited_instructors?.length})` : (['Pending Approval', 'Pending Your Approval', 'Pending Manager Approval'].includes(eventData?.status_content) || eventData?.status_content.toLowerCase().includes('pending')) && `Accepted (${eventData?.accepted_users?.length})`}</div>
            <div>
              <AngleIcon classNames={`w-3 h-4 transform ${openAccordionKey.includes('0') ? 'rotate-180' : ''}`}/>
            </div>
          </Accordion.Toggle>
          {['Open', 'Re-Opened'].includes(eventData?.status_content) && !eventData?.show_manger_request_buttons && eventData?.show_resend_invite_button && eventData?.pending_subs?.length > 0 &&
            <ResendInviteAllButtonComponent eventData={eventData} updateEventData={handleUpdateEvent} handleAlert={handleAlert} eventPreview={true} classNames={eventData?.pending_subs?.length > 8 && accordionOpen ? 'scroll-added' : ''}/>
          }
          <Accordion.Collapse eventKey="0">
            <Card.Body className="p-0">
              <div className="mt-3 max-h-80 overflow-auto">
                {(['Open', 'Re-Opened'].includes(eventData?.status_content) || eventData?.show_manger_request_buttons) &&
                  <Fragment>
                    {((['Open', 'Re-Opened'].includes(eventData?.status_content) && eventData?.pending_subs) || (eventData?.show_manger_request_buttons && eventData?.invited_instructors))?.map((usr, i) => 
                      (<div key={i} className='flex items-center gap-2 justify-between p-2'>
                        <button className="flex items-center gap-2" onClick={() => handleShowInstructorDetails(usr)}>
                          <img src={usr?.image} className='w-6 h-6 rounded-full' />
                          <div>
                            <div className='text-gray-900 text-xs font-semibold flex gap-1'>{usr?.full_name}</div>
                          </div>
                        </button>
                        {eventData?.show_manger_request_buttons ?
                          <Fragment>
                            {(['business_admin', 'Business Admin', 'account_owner', 'Account Owner'].includes(user?.role) || eventData?.admins.includes(user?.id) || eventData?.gfms.includes(user?.id)) &&
                              <button className={`flex justify-center items-center shadow-md rounded-full h-6 w-6 cursor-pointer bg-white ${disableApproveBtn ? 'opacity-50 cursor-not-allowed' : ''}`} onClick={() => processRemoveUserFromEvent(usr?.id)} disabled={disableApproveBtn}>
                                <TashIcon classNames={'text-black w-3.5 h-3.5'}/>
                              </button>
                            }
                          </Fragment>
                        :
                          (['Open', 'Re-Opened'].includes(eventData?.status_content) && usr?.show_resend_invite_button &&
                            <ResendInviteButtonComponent sub={usr} eventData={eventData} updateEventData={handleUpdateEvent} handleAlert={handleAlert} eventPreview={true}/>
                          )
                        }
                      </div>)
                    )}
                  </Fragment>
                }
                {(['Pending Approval', 'Pending Your Approval', 'Pending Manager Approval'].includes(eventData?.status_content) || eventData?.status_content.toLowerCase().includes('pending')) && 
                  <Fragment>
                    {eventData?.accepted_users?.map((usr, i) => 
                      (<div key={i} className='flex items-center justify-between gap-2 p-2'>
                        <button className="flex items-center gap-2" onClick={() => handleShowInstructorDetails(usr)}>
                          <img src={usr?.image} className='w-6 h-6 rounded-full' />
                          <div>
                            <div className='custom-blue-200 text-xs font-semibold flex gap-1'>{usr?.full_name}</div>
                          </div>
                        </button>
                        {eventData?.canApprove &&
                          <div className="flex gap-1 items-center relative">
                            {(usr?.api_sync_error_message && usr?.api_sync_error_message !== '') &&
                              <Fragment>
                                <ExclamationIcon classNames={"text-red-600 h-3.5 w-3.5 inline-block ml-1"} componentId={eventId.toString()+'_'+usr?.id.toString()}/>
                                <ReactTooltip className='max-w-xs text-center react-tooltip-z-index-max' id={eventId.toString()+'_'+usr?.id.toString()} place="top" effect="solid">
                                  {usr?.api_sync_error_message}
                                </ReactTooltip>
                              </Fragment>
                            }
                            <button className={`tc-11 flex items-center justify-center border-green-custom rounded-2xl h-8 gap-0.5 px-5 text-green-c ${(disableApproveBtn) ? 'opacity-50 cursor-not-allowed' : ''}`} onClick={() => handleApprove(usr?.sub_request_id)} disabled={disableApproveBtn}>
                              Approve
                            </button>
                          </div>
                        }
                      </div>)
                    )}
                  </Fragment>
                }
              </div>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </Fragment>
  )
}