import React, { Fragment, useState, useEffect, } from "react";
import { LocationPinIcon } from '../../../../../../subRequests/assets';
import Select from 'react-select';
import { customSearchDropDownStyles } from '../../../../../helpers';

export default function LocationDropDownComponent(props) {
  const { instructorLocations, handleSaveLocation, selectedLocationId, selectedLocationName, i, locationOptions, loading } = props
  const [ selectedOption, setSelectedOption ] = useState(selectedLocationId !== '' ? {value: selectedLocationId, label: selectedLocationName} : null)
  const [inputValue, setInputValue] = useState(selectedLocationId !== '' ? selectedLocationName : '');

  useEffect(() => {
    if (selectedLocationId !== '') setSelectedOption({value: selectedLocationId, label: selectedLocationName})
  }, [selectedLocationId, selectedLocationName])

  const handleClick = () => {
    let div = $('.special-container-class')
    if(div.hasClass('pb-12')){
      div.removeClass('pb-12')
    }else{
      div.addClass('pb-12')
    }
  }

  const handleRemoveExtraPadding = () => {
    let div = $('.special-container-class')
    if(div.hasClass('pb-12')){
      div.removeClass('pb-12')
    }
  }

  const handleSelectedOption = (e) => {
    handleSaveLocation(parseInt(e.value, 10), e.label)
    setSelectedOption(e)
    setInputValue(e ? e.label : '');
  }

  const handleMenuOpen =  (e) => {
    $(`.location-need-border-${i}`).addClass('filter-dynamic-border')
    handleClick()
  }

  const handleMenuClose =  (e) => {
    $(`.location-need-border-${i}`).removeClass('filter-dynamic-border')
    handleRemoveExtraPadding()
    processBackFill()
  }

  const processBackFill = () => {
    if(inputValue == '' || selectedOption == null){
      setSelectedOption(selectedLocationId !== '' ? {value: selectedLocationId, label: selectedLocationName} : null)
      setInputValue(selectedLocationId !== '' ? selectedLocationName : '');
    }else if(inputValue !== selectedOption.label){
      setInputValue(selectedLocationId !== '' ? selectedLocationName : '');
    }
  }

  const handleInputChange = (newValue, { action }) => {
    if (action === 'input-change') {
      if(newValue !==  ''){
        setInputValue(newValue);
      }else{
        setInputValue('');
        setSelectedOption(null);
      }
    }
    return newValue;
  };

  return(
    <Fragment>
      <div className="relative filters_box">
        <LocationPinIcon classNames={'w-5 h-5 text-neutral-500 absolute top-3 left-3 z-10'}/>
        <Select
          className={`flex justify-center w-full rounded-2xl pl-8 pr-2 py-2.5 bg-gray-custom-50 text-sm text-gray-900 focus:outline-none focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500 h-11 gap-1 items-center border-2 border-transparent location-need-border-${i} class-container`}
          classNamePrefix="r-select"
          placeholder="Select location"
          value={selectedOption}
          onChange={handleSelectedOption}
          options={locationOptions}
          onMenuOpen={handleMenuOpen}
          onMenuClose={handleMenuClose}
          styles={customSearchDropDownStyles}
          inputValue={inputValue}
          defaultInputValue={inputValue}
          onInputChange={handleInputChange}
          isLoading={loading}
        />
      </div>
    </Fragment>
  )
}