import React from 'react'

function getHeaders(token, subdomain){  
  if (token !== undefined) {
    let obj = {
      'Accept': 'application/vnd.netgym.v3',
      'Content-Type': 'application/json',
      'Authorization': eval(`${token}`),
      'subdomain': localStorage.getItem("businessID")
    }
    return obj    
  }  
}

export const getLocationsSkills = async (user_token, subdomain) => {    
  return await fetch(`/api/v3/messenger/get_location_skill`,{
    headers: getHeaders(user_token, subdomain)
  });  
}

export const getMessageAudience = async (user_token, subdomain, search, locations, skills, activeUsersOnly, homeClubs) => {    
  return await fetch(`/api/v3/get_message_audience?text=${search}&location_ids=${locations}&category_ids=${skills}&active_users_only=${activeUsersOnly}&home_club_ids=${homeClubs}`,{
    headers: getHeaders(user_token, subdomain)
  });  
}

export const sendMessage = async (user_token, subdomain, params) => {
  return await fetch(`/api/v3/messenger/save`,{
    method: 'POST',
    body: JSON.stringify(params),
    headers: getHeaders(user_token, subdomain)
  });  
}

export const getMessages = async (user_token, subdomain, archive, sort_by, message_type, search, sortName, sortFalg, user_ids, page) => {
  return await fetch(`/api/v3/messenger/messages?archive=${archive}&sort_by=${sort_by}&message_type=${message_type}&text=${search}&sort_name=${sortName}&sortReverse=${sortFalg}&user_ids=${user_ids}&page=${page}`,{
    headers: getHeaders(user_token, subdomain)
  });  
}

export const getMessageTypeIcone = (message_type) => {
  switch (message_type) {
    case 'event':
      return (
        <svg xmlns="http://www.w3.org/2000/svg" className="text-dark-blue h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
          <path fillRule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clipRule="evenodd" />
        </svg>
      )
      break;
    case 'announcement':
      return (
        <svg xmlns="http://www.w3.org/2000/svg" className="text-dark-blue h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
          <path fillRule="evenodd" d="M18 3a1 1 0 00-1.447-.894L8.763 6H5a3 3 0 000 6h.28l1.771 5.316A1 1 0 008 18h1a1 1 0 001-1v-4.382l6.553 3.276A1 1 0 0018 15V3z" clipRule="evenodd" />
        </svg>
      )
      break;
    case 'compliance':
      return (
        <svg xmlns="http://www.w3.org/2000/svg" className="text-dark-blue h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
          <path fillRule="evenodd" d="M3 6a3 3 0 013-3h10a1 1 0 01.8 1.6L14.25 8l2.55 3.4A1 1 0 0116 13H6a1 1 0 00-1 1v3a1 1 0 11-2 0V6z" clipRule="evenodd" />
        </svg>
      )
      break;
    case 'permanent_class_opening':
      return (
        <img className="h-4 w-4" src={"/assets/permanent_classs_opening_icon.svg"} />
      )
      break;
  }
}

export const getTypeIcon = (type) => {
  switch (type) {
    case 'event':
      return (
        <svg xmlns="http://www.w3.org/2000/svg" className="text-green-500 h-3 w-3" viewBox="0 0 20 20" fill="currentColor">
          <path d="M10 2a6 6 0 00-6 6v3.586l-.707.707A1 1 0 004 14h12a1 1 0 00.707-1.707L16 11.586V8a6 6 0 00-6-6zM10 18a3 3 0 01-3-3h6a3 3 0 01-3 3z" />
        </svg>
      )
      break;
    case 'announcement':
      return(
        <svg xmlns="http://www.w3.org/2000/svg" className="text-green-500 h-3 w-3" viewBox="0 0 20 20" fill="currentColor">
          <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z" clipRule="evenodd" />
        </svg>
      )
      break;
    case 'compliance':
      return (
        <svg xmlns="http://www.w3.org/2000/svg" className="text-green-500 h-3 w-3" viewBox="0 0 20 20" fill="currentColor">
        <path fillRule="evenodd" d="M3 6a3 3 0 013-3h10a1 1 0 01.8 1.6L14.25 8l2.55 3.4A1 1 0 0116 13H6a1 1 0 00-1 1v3a1 1 0 11-2 0V6z" clipRule="evenodd" />
      </svg>
      )
      break;
    case 'permanent_class':
      return(
        <svg xmlns="http://www.w3.org/2000/svg" className="text-green-500 h-3 w-3" viewBox="0 0 20 20" fill="currentColor">
          <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z" clipRule="evenodd" />
        </svg>
      )
      break;
  }
}

export const getMessage = async (user_token, subdomain, id) => {
  return await fetch(`/api/v3/messenger/message_detail?id=${id}`,{
    headers: getHeaders(user_token, subdomain)
  });  
}

export const getMessageTypeIconeWithSize = (message_type, size) => {
  switch (message_type) {
    case 'messenger':
      return (
        <svg xmlns="http://www.w3.org/2000/svg" className={`text-dark-blue h-${size} w-${size}`} viewBox="0 0 20 20" fill="currentColor">
          <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
          <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
        </svg>
      )
      break;
    case 'event':
      return (
        <svg xmlns="http://www.w3.org/2000/svg" className={`text-dark-blue h-${size} w-${size}`} viewBox="0 0 20 20" fill="currentColor">
          <path fillRule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clipRule="evenodd" />
        </svg>
      )
      break;
    case 'announcement':
      return (
        <svg xmlns="http://www.w3.org/2000/svg" className={`text-dark-blue h-${size} w-${size}`} viewBox="0 0 20 20" fill="currentColor">
          <path fillRule="evenodd" d="M18 3a1 1 0 00-1.447-.894L8.763 6H5a3 3 0 000 6h.28l1.771 5.316A1 1 0 008 18h1a1 1 0 001-1v-4.382l6.553 3.276A1 1 0 0018 15V3z" clipRule="evenodd" />
        </svg>
      )
      break;
    case 'compliance':
      return (
        <svg xmlns="http://www.w3.org/2000/svg" className={`text-dark-blue h-${size} w-${size}`} viewBox="0 0 20 20" fill="currentColor">
          <path fillRule="evenodd" d="M3 6a3 3 0 013-3h10a1 1 0 01.8 1.6L14.25 8l2.55 3.4A1 1 0 0116 13H6a1 1 0 00-1 1v3a1 1 0 11-2 0V6z" clipRule="evenodd" />
        </svg>
      )
      break;
    case 'permanent_class':
      return (
        <svg xmlns="http://www.w3.org/2000/svg" className={`text-dark-blue h-${size} w-${size}`} viewBox="0 0 20 20" fill="currentColor">
          <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd" />
        </svg>
      )
      break;
    case 'archive':
      return (
        <svg xmlns="http://www.w3.org/2000/svg" className={`text-dark-blue h-${size} w-${size}`} viewBox="0 0 20 20" fill="currentColor">
          <path d="M4 3a2 2 0 100 4h12a2 2 0 100-4H4z" />
          <path fillRule="evenodd" d="M3 8h14v7a2 2 0 01-2 2H5a2 2 0 01-2-2V8zm5 3a1 1 0 011-1h2a1 1 0 110 2H9a1 1 0 01-1-1z" clipRule="evenodd" />
        </svg>
      )
      break;
    case 'permanent_class_opening':
      return (
        <img className={`h-${size} w-${size}`} src={"/assets/permanent_classs_opening_icon.svg"} />
      )
      break;
  }
}

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const capitalizeFirstLetterAndRemoveUnderscore = (string) => {
  return (string.charAt(0).toUpperCase() + string.slice(1)).replace('_', ' ');
}

export const searchPost = (val, all_posts) => {
  let searchTerm = val.trim()
  let all_data = all_posts
  if(searchTerm === ''){
    return all_data
  }else{
    var foundData = all_data.filter(function (entry) {
      return (entry.title.toLowerCase().includes(searchTerm.toLowerCase()));
    });
    return foundData
  }
}

export const updatePost = async (user_token, subdomain,response, emoji, id) => {
  return await fetch(`/api/v3/messenger/message_update`,{
    method: 'PATCH',
    body: JSON.stringify({
      response: response,
      emoji: emoji,
      id: id
    }),
    headers: getHeaders(user_token, subdomain)
  });
}

export const uploadAttachments = async (user_token, subdomain, file, fileId ) => {
  const formData = new FormData();
  formData.append(
    "file",
    file,
    file.name
  );

  let url = `/api/v3/messenger/upload?upload=${false}&file_id=${fileId}`
 
  return await fetch(url,{
    method: "POST",    
    body: formData,
    headers: {
      'Accept': 'application/vnd.netgym.v3',
      'Authorization': eval(`${user_token}`),
      'subdomain': localStorage.getItem("businessID")
    }
  });
}

export const uploadImage = async (user_token, subdomain, formData) => {
  let url = `/api/v3/messenger/upload?upload=${true}`
 
  return await fetch(url,{
    method: "POST",    
    body: formData,
    headers: {
      'Accept': 'application/vnd.netgym.v3',
      'Authorization': eval(`${user_token}`),
      'subdomain': localStorage.getItem("businessID")
    }
  });
}

export const saveComment = async (user_token, subdomain, postId, comment, tagged_user_ids, group_tags, encoded_comment) => {  
  return await fetch(`/api/v3/messenger/comment_save?id=${postId}`,{
    method: "POST",
    body: JSON.stringify({comment, tagged_user_ids, group_tags, encoded_comment}),
    headers: getHeaders(user_token, subdomain)
  });
}

export const getResponce = async (user_token, subdomain, postId) => {  
  return await fetch(`/api/v3/messenger/message_metrics?id=${postId}&paginate=true`,{
    headers: getHeaders(user_token, subdomain)
  });
}

export const editPost = async (user_token, subdomain, params) => {
  return await fetch(`/api/v3/messenger/edit_post`,{
    method: 'PATCH',
    body: JSON.stringify(params),
    headers: getHeaders(user_token, subdomain)
  });  
}

export const deletePost = async (user_token, subdomain, postId) => {
  return await fetch(`/api/v3/messenger/delete_post?id=${postId}`,{
    method: 'DELETE',
    headers: getHeaders(user_token, subdomain)
  });  
}

export const searchAllUser = async (user_token, subdomain, search) => {    
  return await fetch(`/api/v3/messenger/all_users?text=${search}`,{
    headers: getHeaders(user_token, subdomain)
  });  
}

export const getSenderUsers = async (user_token, subdomain, search) => {    
  return await fetch(`/api/v3/messenger/sender_users?text=${search}`,{
    headers: getHeaders(user_token, subdomain)
  });  
}

export const getSenderUsersPosts = async (user_token, subdomain, user_ids, message_type) => {    
  return await fetch(`/api/v3/messenger/all_users_posts?user_ids=${user_ids}&message_type=${message_type}`,{
    headers: getHeaders(user_token, subdomain)
  });  
}

export const changeNotificationForPost = async (user_token, subdomain, postId, notification_type) => {    
  return await fetch(`/api/v3/messenger/notification_update`,{
    method: 'POST',
    body: JSON.stringify({
      'id': postId,
      'notification_type': notification_type
    }),
    headers: getHeaders(user_token, subdomain)
  });  
}

export const saveDraft = async (user_token, subdomain, params) => {
  return await fetch(`/api/v3/messenger/draft`,{
    method: 'POST',
    body: JSON.stringify(params),
    headers: getHeaders(user_token, subdomain)
  });  
}

export const archivePost = async (user_token, subdomain, postId) => {
  return await fetch(`/api/v3/messenger/archive?id=${postId}`,{
    method: 'PATCH',
    headers: getHeaders(user_token, subdomain)
  });  
}

export const getPostTags = async (user_token, subdomain, postIds) => {    
  return await fetch(`/api/v3/messenger/audience_list?id=${postIds}`,{
    headers: getHeaders(user_token, subdomain)
  });  
}

export const deleteComment = async (user_token, subdomain, comment_id, postId) => {    
  return await fetch(`/api/v3/messenger/comment_delete?id=${postId}&comment_id=${comment_id}`,{
    method: 'DELETE',
    headers: getHeaders(user_token, subdomain)
  });  
}

export const editComment = async (user_token, subdomain, postId, comment, tagged_user_ids, comment_id, group_tags, encoded_comment) => {  
  return await fetch(`/api/v3/messenger/edit_comment`,{
    method: "PATCH",
    body: JSON.stringify({comment, tagged_user_ids, comment_id, id: postId, group_tags, encoded_comment}),
    headers: getHeaders(user_token, subdomain)
  });
}

export const getComments = async (user_token, subdomain, postId) => {  
  return await fetch(`/api/v3/messenger/show_comments?id=${postId}`,{
    headers: getHeaders(user_token, subdomain)
  });
}

export const createGroup = async (user_token, subdomain, params) => {
  return await fetch(`/api/v3/messenger/create_group`,{
    method: 'POST',
    body: params,
    headers: {
      'Accept': 'application/vnd.netgym.v3',
      'Authorization': eval(`${user_token}`),
      'subdomain': localStorage.getItem("businessID")
    }
  });  
}

export const getGroupDetails = async (user_token, subdomain, groupId) => {  
  return await fetch(`/api/v3/messenger/show_group?stream_id=${groupId}`,{
    headers: getHeaders(user_token, subdomain)
  });
}

export const updateGroup = async (user_token, subdomain, params) => {
  return await fetch(`/api/v3/messenger/edit_group`,{
    method: 'PATCH',
    body: params,
    headers: {
      'Accept': 'application/vnd.netgym.v3',
      'Authorization': eval(`${user_token}`),
      'subdomain': localStorage.getItem("businessID")
    }
  });  
}

export const searchDmAllUser = async (user_token, subdomain, search) => {    
  return await fetch(`/api/v3/messenger/all_users_list?text=${search}`,{
    headers: getHeaders(user_token, subdomain)
  });  
}

export const updateChannelNotification = async (user_token, subdomain, notificationType, channelType, channelId) => {
  return await fetch(`/api/v3/messenger/update_channel_notifications`,{
    method: "PATCH",
    body: JSON.stringify({duration: notificationType, channel_type: channelType, stream_id: channelId}),
    headers: getHeaders(user_token, subdomain)
  });
}

export const approveUser = async (user_token, subdomain, userId, postId, response) => {
  return await fetch(`/api/v3/messenger/approve_user`,{
    method: 'PATCH',
    body: JSON.stringify({
      user_id: userId,
      post_id: postId,
      response: response
    }),
    headers: getHeaders(user_token, subdomain)
  });
}

export const cancelPermanentClass = async (user_token, subdomain, postId, status) => {
  return await fetch(`/api/v3/messenger/cancel_permanent_class`,{
    method: 'PATCH',
    body: JSON.stringify({
      post_id: postId,
      status: status
    }),
    headers: getHeaders(user_token, subdomain)
  });
}

export const hideItems = (cls) => {
  $('.filter_items').each(function(i, obj) {
    if($(obj).hasClass(`${cls}_items`)){
      $(`.${cls}_items`).toggleClass("hidden")
    }else{
      $(obj).addClass("hidden")
    }
  });
}
export const undoApprovedClass = async (user_token, subdomain, postId) => {
  return await fetch(`/api/v3/messenger/undo_approved_class`,{
    method: 'PATCH',
    body: JSON.stringify({
      post_id: postId
    }),
    headers: getHeaders(user_token, subdomain)
  });
}

export const checkGroupPresent = async (user_token, subdomain, params) => {
  return await fetch(`/api/v3/messenger/check_group_present`,{
    method: 'POST',
    body: JSON.stringify({group_data: params}),
    headers: getHeaders(user_token, subdomain)
  }); 
}

export const getResponceDetail = async (user_token, subdomain, postId, type, page, pageSize) => {  
  return await fetch(`/api/v3/messenger/message_metrics_detail?id=${postId}&type=${type}&page=${page}&page_size=${pageSize}`,{
    headers: getHeaders(user_token, subdomain)
  });
}

export const editDirectMesage = async (user_token, subdomain, params) => {
  return await fetch(`/api/v3/messenger/edit_direct_message`,{
    method: 'POST',
    body: JSON.stringify(params),
    headers: getHeaders(user_token, subdomain)
  });  
}

export const possibleRoles = () => {
  return ['Account Owner', 'Business Admin', 'Regional Admin', 'Fitness Manager', 'Fitness Instructor', 'Ops']
}

export const checkLastSavedHigherRoles = (allRoles, selectedRoles, currentUserRole) => {
  let higherRoles = disabledRolesList(currentUserRole, allRoles)
  return higherRoles.filter(role => selectedRoles.includes(role))
}

export const disabledRolesList = (currentUserRole, rolesList) => {
  let index = rolesList.indexOf(currentUserRole);
  if (index !== -1) {
    let higerLevels = rolesList.slice(0, index+1);
    return higerLevels
  }
  return []
}

export function arrayDifference(arr1, arr2) {
  const set2 = new Set(arr2);
  return arr1.filter(element => !set2.has(element));
}