import React, { Fragment, useState } from 'react';
import { ExclamationIcon } from '../../../../subRequests/assets';
import ReactTooltip from "react-tooltip";
import { axiosRequest } from '../../../../subRequests/helpers/helpers';

export default function ApproveButtonComponent(props) {
  const { sub, eventData, handleUpdateEvent, handleAlert } = props
  const [ disableRemoveButton, setDisableRemoveButton ] = useState(false)

  const handleApprove = (acceptedUserId) => {
    setDisableRemoveButton(true)
    let url = `/api/v2/sub_requests/update_status`
    let params = {
      status: 'confirm',
      event_id: eventData?.id,
      inst_id: acceptedUserId,
      gql_event_details: true,
      preview: false
    }
    axiosRequest(url, 'PATCH', params, 'data').then((res) => {
      if(Object.keys(res).includes('status') && res?.status === 200){
        handleAlert(true, 'Status Updated', 'success');
        setTimeout(() => {
          handleAlert(false, '', ''); 
          handleUpdateEvent(res?.event);
        }, 3000)
      }else{
        handleAlert(true, res?.error, 'danger');
        setTimeout(() => {
          handleAlert(false, '', ''); 
        }, 3000)
      }
      setDisableRemoveButton(false)
    })
  }

  return(
    <Fragment>
      <div>
        <div className="flex gap-2 items-center">
          {(sub?.api_sync_error_message && sub?.api_sync_error_message !== '') &&
            <Fragment>
              <ExclamationIcon classNames={"text-red-600 h-4 w-4 inline-block ml-1"} componentId={eventData?.id.toString()+'_'+sub?.id.toString()}/>
              <ReactTooltip className='max-w-xs text-center react-tooltip-z-index-max' id={eventData?.id.toString()+'_'+sub?.id.toString()} place="top" effect="solid">
                {sub?.api_sync_error_message}
              </ReactTooltip>
            </Fragment>
          }
          <button className={`hidden lg:flex items-center px-3 py-2 rounded-2xl text-sm font-semibold h-9 ${sub?.status === 'confirm' ? 'bg-green-custom text-white' : 'border-green-custom text-green-c'} ${(disableRemoveButton || sub?.status === 'confirm' || ['Approved', 'No Show'].includes(eventData?.status)) ? 'opacity-50 cursor-not-allowed' : ''}`} onClick={() => handleApprove(sub?.sub_request_id)} disabled={disableRemoveButton || sub?.status === 'confirm' || ['Approved', 'No Show'].includes(eventData?.status)}>
            {sub?.status === 'confirm' ? 'Approved' : 'Approve'}
          </button>
          <button className={`flex lg:hidden items-center justify-center rounded-2xl tc-11 h-7 gap-0.5 px-3 ${sub?.status === 'confirm' ? 'bg-green-custom text-white' : 'border-green-custom text-green-c'} ${(disableRemoveButton || sub?.status === 'confirm' || ['Approved', 'No Show'].includes(eventData?.status)) ? 'opacity-50 cursor-not-allowed' : ''}`} onClick={() => handleApprove(sub?.sub_request_id)} disabled={disableRemoveButton || sub?.status === 'confirm' || ['Approved', 'No Show'].includes(eventData?.status)}>
            {sub?.status === 'confirm' ? 'Approved' : 'Approve'}
          </button>
        </div>
      </div>
    </Fragment>
  )
}