import React, { Fragment, Component } from 'react'
import Pagination from "react-js-pagination";
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/outline'
import { hideItems, updateAnalyticsLocation, axiosGetExportRequest, axiosGetRequest } from './../../helpers'
import DatePicker from "react-datepicker";

export default class HomeClubReport extends Component{

  constructor(props){
    super(props)
    const date = new Date();
    date.setMonth(date.getMonth() - 1, 1);
    this.state = {
      startDate: date,
      endDate: new Date(),
      homeClubs: [],
      selectedHomeClub: '',
      loadTableData: true,
      activePage: 1,
      pageSize: 20,
      sortType: 'name',
      sortReverse: false,
      homeClubsData: [],
      totalData: 0,
      baseCount: 0
    }
  }

  componentDidMount(){
    if (this.props.pageLoaded){
      this.getHomeClubsData()
    }
  }

  getHomeClubsData = () => {
    axiosGetRequest("/api/v3/analytics/get_all_locations").then(result => {
      this.setState({
        homeClubs: result
      })
    })
  }

  UNSAFE_componentWillReceiveProps(nextProps){
    this.setState({
      
    })
  }

  handleStartDateChange = (e) => {
    this.setState({
      startDate: e === null ? '' : e
    })
  }

  handleEndDateChange = (e) => {
    this.setState({
      endDate: e === null ? '' : e
    })
  }

  handleSearchResult = (e) => {
    const { selectedHomeClub, startDate, endDate, sortType, sortReverse, activePage, pageSize} = this.state
    if (selectedHomeClub === '' || startDate === '' || endDate === '' || sortType === '' || sortReverse === '' || activePage === '' || pageSize === ''){
      console.log("Empty call..")
      return
    }

    this.handleFetchHomeClubData(selectedHomeClub, startDate, endDate, activePage, pageSize, sortType, sortReverse)
  }

  handleFetchHomeClubData = (selectedHomeClub, startDate, endDate, activePage, pageSize, sortType, sortReverse) => {
    this.setState({loadTableData: false})
    axiosGetRequest(`/api/v3/analytics/reports?home_club_id=${selectedHomeClub}&start_date=${startDate}&end_date=${endDate}&page=${activePage}&pageSize=${pageSize}&sortType=${sortType}&sortReverse=${sortReverse}`).then(result => {
      if(result?.status === 200){
        this.setState({
          homeClubsData: result?.results,
          activePage: parseInt(result?.page),
          pageSize: parseInt(result?.page_size),
          totalData: result?.total,
          baseCount: (parseInt(result?.page)-1)*parseInt(result?.page_size),
          loadTableData: true
        })
      }else{
        this.setState({
          loadTableData: true
        })
      }
    })


  }

  handleExportData = (e) => {
    const { selectedHomeClub, startDate, endDate, sortType, sortReverse, activePage, pageSize} = this.state
    let url = `/api/v3/analytics/export_home_club_report?id=${selectedHomeClub}&start_date=${startDate}&end_date=${endDate}&sortType=${sortType}&sortReverse=${sortReverse}`
    let file_name = "instructor_homeclub.csv"
    axiosGetExportRequest(url).then(result => { 
      const url = window.URL.createObjectURL(result)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', file_name == "" ? "instructor_data.csv" : file_name)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)        
    })
  }

  handleSelecteHomeClub = (e, homeClubId) => {
    this.setState({selectedHomeClub: homeClubId})
  }

  handlePageChange = (pageNumber) => {
    this.setState({activePage: pageNumber})
    this.handleFetchHomeClubData(this.state.selectedHomeClub, this.state.startDate, this.state.endDate, pageNumber, this.state.pageSize, this.state.sortType, this.state.sortReverse)
  }

  handleSort = (e, param, type) => {
    e.preventDefault()
    let sort_desc = false
    if(type === 'asc'){
      sort_desc = false
    }else{
      let sortRev =  this.state.sortReverse
      if(sortRev === true){
        sort_desc = false
      }
      else{
        sort_desc = true
      }
    }
    this.setState({
      sortType: param,
      sortReverse: sort_desc
    })
    this.handleFetchHomeClubData(this.state.selectedHomeClub, this.state.startDate, this.state.endDate, this.state.activePage, this.state.pageSize, param, sort_desc)
  }

  checkTypeName = (type) => {
    let classes =  this.state.homeClubs;
    let result = classes.filter((item) => {return item?.id === type})
    if (result?.length == 0){
      return 'Select Home Club'
    }
    return result[0]?.name;
  }

  render(){
    const { startDate, endDate, homeClubs, selectedHomeClub, loadTableData, homeClubsData, sortType, sortReverse, activePage, pageSize, totalData, baseCount } = this.state
    return(
      <Fragment>
        <div className='flex gap-4 py-4 flex-wrap'>
          <div className='h-9 flex items-center sm:w-60 w-full filters_box'>
            <Menu as="div" className="w-full relative block text-left ">
              <div>
                <Menu.Button className="inline-flex items-center h-9 justify-center w-full rounded border border-gray-300 shadow-sm pl-4 pr-2 py-2 bg-white text-sm text-gray-400 focus:outline-none focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500 location_items_button">
                  {selectedHomeClub === '' ? 'Select Home Club' : <p className = "text-gray-900">{this.checkTypeName(selectedHomeClub)}</p>}
                  <ChevronDownIcon className="ml-auto w-4 mr-0.5 text-gray-400" aria-hidden="true" />
                </Menu.Button>
              </div>
              <Transition
                as={Fragment}
                show={true}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95">
                <Menu.Items className="origin-top-right z-20 absolute right-0 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none hidden location_items filter_items">
                  <div className="py-2 max-h-60 overflow-y-auto">
                    {homeClubs.map((homeClub, i) =>
                      <Menu.Item key = {i}>
                        {({ active }) => (
                          <a className={`flex items-center text-gray-900 text-sm px-3 cursor-pointer hover:bg-gray-100 py-2.5 ${selectedHomeClub == homeClub?.id ? 'bg-gray-100' : ''}`} onClick= {(e) => {this.handleSelecteHomeClub(e, homeClub.id); hideItems('location')}}>
                            <div className='pl-1'>{homeClub?.name}</div>
                          </a>
                        )}
                      </Menu.Item>
                    )}
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
          <div className='flex items-center sm:w-60 w-full'>
            <div className='w-full bg-white border border-gray-300 shadow-sm rounded-md h-9 flex items-center '>
              <DatePicker
                selected={startDate}
                className="h-9 rounded-md w-full border-0 shadow-sm pl-4 pr-2 py-2 text-sm text-gray-900 focus:outline-none focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500 cursor-pointer relative z-10 bg-transparent"
                autoFocus={false}
                dateFormat="M/d/yyyy"
                onChange={(e) => this.handleStartDateChange(e)}
                calendarType="US" 
                placeholderText="Start Date"                    
              />
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5 absolute top-2.5 right-2.5">
                <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5" />
              </svg>
            </div>
          </div>
          <div className='bg-white border border-gray-300 shadow-sm rounded-md h-9 flex items-center sm:w-60 w-full'>
            <DatePicker
              selected={endDate}
              className="h-9 rounded-md w-full border-0 shadow-sm pl-4 pr-2 py-2 text-sm text-gray-900 focus:outline-none focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500 cursor-pointer relative z-10 bg-transparent"
              autoFocus={false}
              dateFormat="M/d/yyyy"
              onChange={(e) => this.handleEndDateChange(e)}
              calendarType="US" 
              placeholderText="End Date"                    
            />
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5 absolute top-2.5 right-2.5">
              <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5" />
            </svg>
          </div>
          <div className='sm:w-36'>
            <div className="flex items-center gap-x-4">
              <input type="submit" value="Search" className={`font-medium cursor-pointer inline-flex ${(selectedHomeClub === '' || startDate === '' || endDate === '') ? 'cursor-not-allowed bg-gray-500' : 'cursor-pointer bg-dark-blue'} text-sm text-white py-2 px-3 rounded-md h-9`} onClick={(e) => this.handleSearchResult(e)} disabled={(selectedHomeClub === '' || startDate === '' || endDate === '')}/>
              <button className={`${(selectedHomeClub === '' || startDate === '' || endDate === '') ? 'cursor-not-allowed' : 'cursor-pointer'}`} onClick={() => this.handleExportData()} disabled={(selectedHomeClub === '' || startDate === '' || endDate === '')}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className={`w-6 h-6 ${(selectedHomeClub === '' || startDate === '' || endDate === '') ? 'text-gray-500' : 'text-black'}`}>
                  <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3" />
                </svg>
              </button>
            </div>
          </div>
        </div>
        <Fragment>
          {!loadTableData &&
            <div className="msg-loading flex flex-col items-center w-full justify-center h-screen">
              <img className = "w-9" src="/assets/business/loading.gif" />
            </div>
          }
          {loadTableData &&
            <div className='overflow-x-auto overflow-y-hidden sm:-mx-6 lg:-mx-8 my-4'>
              <div className='py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8 min-h300'>
                <div className='shadow border overflow-hidden border-b border-gray-200 sm:rounded-lg'>
                  <table className='min-w-full divide-y divide-gray-200'>
                    <thead className='bg-gray-50'>
                      <tr>
                        <th className='px-3 py-3 text-left text-xs font-medium text-gray-500'>
                          <div className='flex justify-center items-center'>
                            <span className='cursor-pointer whitespace-nowrap text-sm'>Instructor Name</span> &nbsp; &nbsp;
                            {sortType !== 'name' &&
                              <svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.handleSort(e, 'name', 'desc')}>
                                <path d="M5 12a1 1 0 102 0V6.414l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L5 6.414V12zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z" />
                              </svg>
                            }
                            {(sortType === 'name' && sortReverse) &&
                              <svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.handleSort(e, 'name', 'asc')}>
                                <path d="M3 3a1 1 0 000 2h11a1 1 0 100-2H3zM3 7a1 1 0 000 2h7a1 1 0 100-2H3zM3 11a1 1 0 100 2h4a1 1 0 100-2H3zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z" />
                              </svg>
                            }
                            {(sortType === 'name' && !sortReverse) &&
                              <svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.handleSort(e, 'name', 'desc')}>
                                <path d="M3 3a1 1 0 000 2h11a1 1 0 100-2H3zM3 7a1 1 0 000 2h5a1 1 0 000-2H3zM3 11a1 1 0 100 2h4a1 1 0 100-2H3zM13 16a1 1 0 102 0v-5.586l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 101.414 1.414L13 10.414V16z" />
                              </svg>
                            }
                          </div>
                        </th>
                        <th className='px-3 py-3 text-left text-xs font-medium text-gray-500'>
                          <div className='flex justify-center items-center'>
                            <span className='cursor-pointer text-sm whitespace-nowrap'>Subbing Location</span>&nbsp; &nbsp;
                            {sortType !== 'location' &&
                              <svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.handleSort(e, 'location', 'desc')}>
                                <path d="M5 12a1 1 0 102 0V6.414l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L5 6.414V12zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z" />
                              </svg>
                            }
                            {(sortType === 'location' && sortReverse) &&
                              <svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.handleSort(e, 'location', 'asc')}>
                                <path d="M3 3a1 1 0 000 2h11a1 1 0 100-2H3zM3 7a1 1 0 000 2h7a1 1 0 100-2H3zM3 11a1 1 0 100 2h4a1 1 0 100-2H3zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z" />
                              </svg>
                            }
                            {(sortType === 'location' && !sortReverse) &&
                              <svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.handleSort(e, 'location', 'desc')}>
                                <path d="M3 3a1 1 0 000 2h11a1 1 0 100-2H3zM3 7a1 1 0 000 2h5a1 1 0 000-2H3zM3 11a1 1 0 100 2h4a1 1 0 100-2H3zM13 16a1 1 0 102 0v-5.586l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 101.414 1.414L13 10.414V16z" />
                              </svg>
                            }
                          </div>
                        </th>
                        <th className='px-3 py-3 text-left text-xs font-medium text-gray-500'>
                          <div className='flex justify-center items-center'>
                            <span className='cursor-pointer text-sm whitespace-nowrap'>Date</span>&nbsp; &nbsp;
                            {sortType !== 'date' &&
                              <svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.handleSort(e, 'date', 'desc')}>
                                <path d="M5 12a1 1 0 102 0V6.414l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L5 6.414V12zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z" />
                              </svg>
                            }
                            {(sortType === 'date' && sortReverse) &&
                              <svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.handleSort(e, 'date', 'asc')}>
                                <path d="M3 3a1 1 0 000 2h11a1 1 0 100-2H3zM3 7a1 1 0 000 2h7a1 1 0 100-2H3zM3 11a1 1 0 100 2h4a1 1 0 100-2H3zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z" />
                              </svg>
                            }
                            {(sortType === 'date' && !sortReverse) &&
                              <svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.handleSort(e, 'date', 'desc')}>
                                <path d="M3 3a1 1 0 000 2h11a1 1 0 100-2H3zM3 7a1 1 0 000 2h5a1 1 0 000-2H3zM3 11a1 1 0 100 2h4a1 1 0 100-2H3zM13 16a1 1 0 102 0v-5.586l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 101.414 1.414L13 10.414V16z" />
                              </svg>
                            }
                          </div>
                        </th>
                        <th className='px-3 py-3 text-left text-xs font-medium text-gray-500'>
                          <div className='flex justify-center items-center'>
                            <span className='cursor-pointer text-sm whitespace-nowrap'>Time</span>&nbsp; &nbsp;
                            {sortType !== 'time' &&
                              <svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.handleSort(e, 'time', 'desc')}>
                                <path d="M5 12a1 1 0 102 0V6.414l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L5 6.414V12zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z" />
                              </svg>
                            }
                            {(sortType === 'time' && sortReverse) &&
                              <svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.handleSort(e, 'time', 'asc')}>
                                <path d="M3 3a1 1 0 000 2h11a1 1 0 100-2H3zM3 7a1 1 0 000 2h7a1 1 0 100-2H3zM3 11a1 1 0 100 2h4a1 1 0 100-2H3zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z" />
                              </svg>
                            }
                            {(sortType === 'time' && !sortReverse) &&
                              <svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.handleSort(e, 'time', 'desc')}>
                                <path d="M3 3a1 1 0 000 2h11a1 1 0 100-2H3zM3 7a1 1 0 000 2h5a1 1 0 000-2H3zM3 11a1 1 0 100 2h4a1 1 0 100-2H3zM13 16a1 1 0 102 0v-5.586l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 101.414 1.414L13 10.414V16z" />
                              </svg>
                            }
                          </div>
                        </th>
                        <th className='px-3 py-3 text-left text-xs font-medium text-gray-500'>
                          <div className='flex justify-center items-center'>
                            <span className='cursor-pointer text-sm whitespace-nowrap'>Duration (in mins)</span>&nbsp; &nbsp;
                            {sortType !== 'duration' &&
                              <svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.handleSort(e, 'duration', 'desc')}>
                                <path d="M5 12a1 1 0 102 0V6.414l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L5 6.414V12zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z" />
                              </svg>
                            }
                            {(sortType === 'duration' && sortReverse) &&
                              <svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.handleSort(e, 'duration', 'asc')}>
                                <path d="M3 3a1 1 0 000 2h11a1 1 0 100-2H3zM3 7a1 1 0 000 2h7a1 1 0 100-2H3zM3 11a1 1 0 100 2h4a1 1 0 100-2H3zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z" />
                              </svg>
                            }
                            {(sortType === 'duration' && !sortReverse) &&
                              <svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.handleSort(e, 'duration', 'desc')}>
                                <path d="M3 3a1 1 0 000 2h11a1 1 0 100-2H3zM3 7a1 1 0 000 2h5a1 1 0 000-2H3zM3 11a1 1 0 100 2h4a1 1 0 100-2H3zM13 16a1 1 0 102 0v-5.586l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 101.414 1.414L13 10.414V16z" />
                              </svg>
                            }
                          </div>
                        </th>
                        <th className='px-3 py-3 text-left text-xs font-medium text-gray-500'>
                          <div className='flex justify-center items-center'>
                            <span className='cursor-pointer text-sm whitespace-nowrap'>Class Name</span>&nbsp; &nbsp;
                            {sortType !== 'title' &&
                              <svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.handleSort(e, 'title', 'desc')}>
                                <path d="M5 12a1 1 0 102 0V6.414l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L5 6.414V12zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z" />
                              </svg>
                            }
                            {(sortType === 'title' && sortReverse) &&
                              <svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.handleSort(e, 'title', 'asc')}>
                                <path d="M3 3a1 1 0 000 2h11a1 1 0 100-2H3zM3 7a1 1 0 000 2h7a1 1 0 100-2H3zM3 11a1 1 0 100 2h4a1 1 0 100-2H3zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z" />
                              </svg>
                            }
                            {(sortType === 'title' && !sortReverse) &&
                              <svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.handleSort(e, 'title', 'desc')}>
                                <path d="M3 3a1 1 0 000 2h11a1 1 0 100-2H3zM3 7a1 1 0 000 2h5a1 1 0 000-2H3zM3 11a1 1 0 100 2h4a1 1 0 100-2H3zM13 16a1 1 0 102 0v-5.586l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 101.414 1.414L13 10.414V16z" />
                              </svg>
                            }
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody className='divide-y divide-gray-200'>
                      {homeClubsData.map((user, i) => 
                        <tr key={i} className='bg-white'>
                          <td className='px-3 py-4'>
                            <div className='text-sm text-gray-500 text-center'>
                              {user?.name}
                            </div>
                          </td>
                          <td className='px-3 py-4'>
                            <div className='text-sm text-gray-500 text-center'>
                              {user?.location}
                            </div>
                          </td>
                          <td className='px-3 py-4'>
                            <div className='text-sm text-gray-500 text-center'>
                              {user?.date}
                            </div>
                          </td>
                          <td className='px-3 py-4'>
                            <div className='text-sm text-gray-500 text-center'>
                              {user?.time}
                            </div>
                          </td>
                          <td className='px-3 py-4'>
                            <div className='text-sm text-gray-500 text-center'>
                              {user?.duration}
                            </div>
                          </td>
                          <td className='px-3 py-4'>
                            <div className='text-sm text-gray-500 text-center'>
                              {user?.class_name}
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                <div className="py-3 mt-2 flex items-center justify-between">
                  <div className='flex justify-between w-full flex-wrap'>
                    <div>
                      <p className='text-sm text-gray-700'>
                        {`Showing ${totalData == 0 ? totalData : baseCount+1} to ${activePage*pageSize > totalData ? totalData : activePage*pageSize} of ${totalData} results`}
                      </p>
                    </div>
                  </div>
                  <div className="custom-pagination order-first sm:order-last mb-3 sm:mb-0">
                    {totalData > pageSize && 
                      <Pagination
                        activePage={activePage}
                        itemsCountPerPage={pageSize}
                        totalItemsCount={totalData}
                        pageRangeDisplayed={9}
                        onChange={this.handlePageChange.bind(this)}
                        itemClassPrev='previous'
                        itemClassNext='next'
                        itemClassFirst='first'
                        itemClassLast='last'
                        hideDisabled={true}
                      />
                    }
                  </div>
                </div>
              </div>
            </div>
          }
        </Fragment>
      </Fragment>
    )
  }
}