import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { ChannelHeader, MessageInput, MessageList, useChatContext, Thread, Window } from 'stream-chat-react'
import CustomChannelHeader from './customChannelHeader';
import CustomChannelInfo from './customChannelInfo';
import Cookies from 'js-cookie';
import { getGroupDetails } from '../helpers'
import { getSudomain } from '../../../helpers'
import AudienceModal from './assets/audienceModal'
import CustomMessageInput from './customMessageInput/customMessageInput'
import NotAllowInputField from './assets/notAllowInputField'

const Container = styled.div`
  width: 100%;
  display: flex;
  background-color: white;
  border-radius:12px;

  .str-chat-header-livestream {
    width: 100%;
    height: 60px;
  }

  .str-chat__list {
    height: calc(100vh - 270px);
  }

  .str-chat__input-flat-wrapper {
    position: absolute;
    bottom: -55px;
    width: 100%;
  }
`;

const InfoContainer = styled.div`
  width: 100%;
  border-radius:12px;
  background-color: white;
  overflow: hidden;
`;

export default function ChannelBody({user, permissions}) {
  const { channel } = useChatContext()
  const [ showEditButton, setShowEditButton ] = useState(false)
  const [ showInputField, setShowInputField ] = useState(true)
  const [ showInfoComponent, setShowInfoComponent ] = useState(false)
  const [ locationTags, setLocationTags ] = useState([])
  const [ skillTags, setSkillTags ] = useState([])
  const [ notification, setNotification ] = useState('do_not_mute')
  const [ audienceList, setAudienceList ] = useState([])
  const [ notificationDuration, setNotificationDuration ] = useState('')
  const [ homeClubTags, setHomeClubTags ] = useState([])

  useEffect(() => {
    if(channel){
      let userRole = channel.data.acknowledged_roles || [user?.role]
      if(!userRole?.includes(user?.role)){
        setShowInputField(false)
      }
      // if(permissions?.business_messages?.groups && user?.role !== 'Fitness Instructor'){
      //   setShowEditButton(true)
      // }
    }
  }, [])

  const handleSetShowInfo = (data) => {
    getGroupDetails(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), channel.id).then(      
      response => response.json()
    )
    .then(result => {
      if(result.status === 200){
        let usr = {full_name: user?.full_name, id: user?.id, image: user?.profile_pic, name_image: `${user?.last_name === '' ? user?.first_name[0] : `${user?.first_name[0]}${user?.last_name[0]}`}`}
        setLocationTags(result?.chatroom?.locations_mobile)
        setSkillTags(result?.chatroom?.categories_mobile)
        setHomeClubTags(result?.chatroom?.home_clubs_mobile)
        setNotification(result?.chatroom?.duration)
        let audeince_list = result?.chatroom?.audience_mobile || []
        audeince_list.push(usr)
        audeince_list.sort((a, b) => a.full_name.localeCompare(b.full_name, 'es', {sensitivity: 'base'}))
        setAudienceList(audeince_list)
        setNotificationDuration(result?.chatroom?.expired_at_string)
        setShowEditButton(result?.chatroom?.show_edit)
        setShowInfoComponent(data)
      }else{
        setLocationTags([])
        setSkillTags([])
        setHomeClubTags([])
        setNotification('do_not_mute')
        setAudienceList([])
        setNotificationDuration('')
        setShowEditButton(false)
        setShowInfoComponent(data)
      }
    })
  }

  const setUserNotification = (data) => {
    setNotification(data)
  }

  if(showInfoComponent){
    const setInfoComponentOff = (data) => {
      setShowInfoComponent(data)
    }
    return(
      <InfoContainer>
        <CustomChannelInfo setInfoComponentOff = {setInfoComponentOff} showEditButton = {showEditButton} locationTags = {locationTags} skillTags = {skillTags} userNotification = {notification} setUserNotification = {setUserNotification} allAudienceList = {audienceList} notificationDuration = {notificationDuration} homeClubTags={homeClubTags}/>
      </InfoContainer>
    )
  }

  const getMessageActions = () => {
      return ["edit", "delete", "react", "reply"];
  };

  const customInput = () => {
    return <NotAllowInputField />
  }

  const customActions = {
    'Copy text': (message) => {
      navigator.clipboard.writeText(message.text || '')
    },
  };
  
  return (
    <React.Fragment>
      <Container>
        <Window>
          <CustomChannelHeader setShowInfo={handleSetShowInfo}/>
          {/* <ChannelHeader /> */}
          <MessageList messageActions={getMessageActions()} customMessageActions={customActions} />
          {showInputField ?
            <MessageInput />
            :
            <NotAllowInputField />
          }
        </Window>
        <Thread additionalMessageInputProps={{ grow: true, Input: showInputField ? CustomMessageInput : customInput }} messageActions={getMessageActions()}/>
      </Container>
    </React.Fragment>
  )
}
