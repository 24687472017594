import React, { Fragment } from "react";
import CustomPopupContainerComponent from './../../../../subRequestDetails/components/customPopupContainerComponent';
import ReactHtmlParser from 'react-html-parser';

export default function MultipleClassesMessageComponent(props) {
  const { showMultipleClassPopup, closeMultipleClassPopup, message, showPopupWithError, showErrorMessage } = props

  return(
    <Fragment>
      <CustomPopupContainerComponent show={showMultipleClassPopup} close={closeMultipleClassPopup} title={''} customWidth={'sm:max-w-md'}>
        <div className="gap-2 px-8 py-4">
          <div className="flex justify-center text-2xl text-black font-bold mb-3">
            NetGym
          </div>
          <div className={`font-medium ${showPopupWithError ? 'second-step-error-message' : 'text-center'} text-sm text-neutral-500 mb-6`}>
            {showPopupWithError ? ReactHtmlParser(showErrorMessage) : message}
          </div>
          <div className="flex justify-center">
            <button className="w-full bg-gray-900 text-white text-center text-sm font-semibold rounded-2xl px-5 py-3.5" onClick={() => closeMultipleClassPopup(false)}>
              Confirm
            </button>
          </div>
        </div>
      </CustomPopupContainerComponent>
    </Fragment>
  )
}